import {Component, viewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatCheckbox} from "@angular/material/checkbox"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"

import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {GoteborgObject, GoteborgService} from "./goteborg.service"
import {LogListComponent} from "../../log-list/log-list.component"
import {PLOT_CONFIG} from "../../../constants"
import {PlotlyMouseEvent} from "../../vide-types"
import {moveMenu} from "../../shared/mat-menu"

@Component({
    selector: 'app-goteborg',
    standalone: true,
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatCheckbox,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './goteborg.component.html',
    styleUrl: './goteborg.component.scss'
})
export class GoteborgComponent extends ComponentCanDeactivate {
    protected readonly figure = toSignal(this.dataService.figure$)
    protected readonly form = this.dataService.form
    protected readonly inProgress = this.dataService.inProgress
    protected readonly logs = this.dataService.logs
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly stations = toSignal(this.dataService.stations$)
    protected readonly stations$ = this.dataService.stations$
    protected readonly trigger = viewChild(MatMenuTrigger)

    protected readonly displayedColumns = [
        'name',
        'active',
        'imported',
        'action',
    ] as const

    constructor(
        private readonly dataService: GoteborgService,
    ) {
        super()
    }

    add = this.dataService.add.bind(this.dataService)
    onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)
    remove = this.dataService.remove.bind(this.dataService)

    override canDeactivate(): boolean {
        return true
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const custom = event.points[0]?.customdata as GoteborgObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()
        if (trigger) {
            trigger.menuData = {element: custom,}
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }
}
