import {Component, input} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatStepperModule} from "@angular/material/stepper"
import {MatTableModule} from "@angular/material/table"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"

import {combineLatest, switchMap} from "rxjs"

import {DiverInstance} from "../../../api/api-types"
import {DiverService} from "../diver.service"
import {InputComponent} from "../../../forms/input/input.component"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"

@Component({
    selector: 'app-existing-instances',
    standalone: true,
    imports: [
        InputComponent,

        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatTableModule,
    ],
    templateUrl: './existing-instances.component.html',
    styleUrl: './existing-instances.component.scss'
})
export class ExistingInstancesComponent {
    readonly diverObject = input.required<SlimObject>()

    readonly installations$ = combineLatest([
        this.dataService.projectNotNull$,
        toObservable(this.diverObject)
    ]).pipe(
        switchMap(([p, o]) => {
            return this.dataService.getDiverInstances(p, o)
        }),
    )

    protected readonly installations = toSignal(this.installations$)
    displayedColumns = [
        'comment',
        "first_date",
        // "id",
        // "object_id",
        // "reference_measurement_id",
        "serial_number",
        "delete",
    ]

    constructor(
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
    }

    delete(element: DiverInstance) {
        this.diverService.deleteInstance(element)
    }
}
