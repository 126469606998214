import {Component, effect, input} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatStepperModule} from "@angular/material/stepper"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyViaWindowModule} from "angular-plotly.js"
import {DiverDatum, LocalDiverData} from "../diver.service"
import {InputComponent} from "../../../forms/input/input.component"
import {LogListComponent} from "../../../log-list/log-list.component"
import {ReferenceDiverService} from "./reference-diver.service"
import {SlimObject} from "../diver.component"

@Component({
    selector: 'app-reference-diver',
    standalone: true,
    imports: [
        InputComponent,
        LogListComponent,

        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    templateUrl: './reference-diver.component.html',
    styleUrl: './reference-diver.component.scss'
})
export class ReferenceDiverComponent {
    readonly diverObject = input.required<SlimObject>()
    readonly localDiverData = input.required<LocalDiverData | null>()
    readonly localCompensatedData = input.required<{
        compensated: readonly DiverDatum[],
        baro: readonly DiverDatum[],
        diver: readonly DiverDatum[],
    } | null>()

    protected readonly figure = toSignal(this.service.refFigure$)
    protected readonly nrInput = toSignal(this.service.nrInputData$)
    protected readonly nrReferenced = toSignal(this.service.nrReferenced$)
    protected readonly possibleReferenceMeasurements = toSignal(this.service.possibleRefMeasurements$, {initialValue: []})
    protected readonly possibleReferenceObjects = toSignal(this.service.possibleRefObjects$)

    protected readonly form = this.service.form
    protected readonly saving = this.service.saving

    constructor(
        private readonly service: ReferenceDiverService,
    ) {
        effect(() => {
            const diver = this.diverObject()
            this.service.diver.next(diver)
            const found = this.possibleReferenceObjects()?.find(o => o.object.id === diver.id)
            if (found) {
                this.form.patchValue({refObject: found})
            }
        }, {allowSignalWrites: true})
        effect(() => {
            const diver = this.localCompensatedData()
            this.service.localCompensatedData.next(diver)
        }, {allowSignalWrites: true})
        effect(() => {
            const diver = this.localDiverData()
            this.service.localDiverData.next(diver)
        }, {allowSignalWrites: true})
    }

    save = this.service.save.bind(this.service)
}
