import {Component, computed, DestroyRef, effect, Inject, LOCALE_ID, OnInit} from '@angular/core'
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatDialog} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Router} from '@angular/router'
import {AsyncPipe, formatNumber} from "@angular/common"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatInputModule} from "@angular/material/input"

import {NgSelectModule} from "@ng-select/ng-select"
import {GEOLOCATION_SUPPORT, GeolocationService} from '@ng-web-apis/geolocation'
import {combineLatest, firstValueFrom, map, of, startWith, switchMap, tap} from 'rxjs'
import {getPreciseDistance} from "geolib"

import {MAX_DEVIATION, NUMBER_FORMAT} from 'src/constants'
import {VideDataService} from '../api/vide-data.service'
import {ComponentCanDeactivate} from '../can-deactivate.guard'
import {ObjectDetailDialogComponent} from '../dialogs/object-detail-dialog/object-detail-dialog.component'
import {
    LocateObjectDialogComponent,
    LocateObjectDialogData,
    LocateObjectDialogResult
} from '../locate-object-dialog/locate-object-dialog.component'
import {CoordinateTransformService} from "../shared/coordinate-transform.service"
import {equalIds, getPageTitle, getResultingValue, isNotNull} from '../shared/vide-helper'
import {objectWithPosition, Unpacked,} from '../vide-types'
import {ABILITY} from "../ability"
import {ErrorCode, Group, Measurement, MeasureType, VideObject} from "../api/api-types"
import {InputComponent} from "../forms/input/input.component"

type History = Readonly<[Measurement | undefined, Measurement | undefined]>
type FormValueType = Unpacked<ReturnType<FieldMeasurementsComponent['newRecordRow']>>['formGroup']['value']

@Component({
    selector: 'app-field-measurements',
    standalone: true,
    templateUrl: './field-measurements.component.html',
    styleUrls: ['./field-measurements.component.scss'],
    imports: [
        AsyncPipe,
        InputComponent,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,
    ]
})
export class FieldMeasurementsComponent extends ComponentCanDeactivate implements OnInit {
    //////////////////////////////////////////////
    //////////////////////////////////////////////
    readonly project = this.dataService.project
    readonly groups = toSignal(this.dataService.groups$, {initialValue: []})
    private readonly utility = toSignal(this.dataService.utility$)
    readonly measureTypes = computed(() => {
        return this.utility()?.measure_type ?? []
    })
    readonly textCodes = computed(() => {
        return this.utility()?.error_code ?? []
    })
    private readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    readonly activeObjects = computed(() => this.objects().filter(o => o.object_status.constant_name === 'object_status_active'))
    readonly groupedObjects = computed(() => {
        const os = this.activeObjects()
        const ids = this.selectedGroup()?.object_ids ?? []
        const pass = {
            title: "In group",
            items: new Array<VideObject>(),
        }
        const fail = {
            title: "Other",
            items: new Array<VideObject>(),
        }
        os.forEach(o => {
            const dest = ids.includes(o.id) ? pass : fail
            dest.items.push(o)
        })
        return [pass, fail]

    })

    readonly newInputRows: Array<Unpacked<ReturnType<typeof this.newRecordRow>>> = []
    readonly groupControl = new FormControl<Group | null>(null)
    readonly automaticTimestampControl = new FormControl(true, {nonNullable: true})
    protected readonly ABILITY = ABILITY
    equalIds = equalIds

    readonly selectedGroup = toSignal(this.groupControl.valueChanges)

    constructor(
        @Inject(GEOLOCATION_SUPPORT) public readonly geolocationSupport: boolean,
        @Inject(LOCALE_ID) private locale: string,
        private readonly route: ActivatedRoute,
        private readonly dataService: VideDataService,
        private readonly title: Title,
        private readonly dialog: MatDialog,
        private readonly geolocation: GeolocationService,
        private readonly router: Router,
        private readonly snackBar: MatSnackBar,
        private readonly transform: CoordinateTransformService,
        private readonly fb: FormBuilder,
        private readonly destroyRef: DestroyRef
    ) {
        super()
        effect(() => {
            this.title.setTitle(getPageTitle(this.project(), 'field app'))
        })
    }

    protected searchFn(term: string, item: VideObject) {
        return item.name.toLocaleLowerCase().includes(term)
    }

    /**
     * This doesn't work for now, don't know why. The ng-select element doesn't let you select any item. Let's keep this around for when it resolves.
     * @param object
     */
    groupedMeasureTypes(object: VideObject | null) {
        const usedMtIds = object?.statistics.map(s => s.measure_type.id) ?? []
        const pass = {
            title: "Used before",
            items: new Array<MeasureType>(),
        }
        const fail = {
            title: "Never used",
            items: new Array<MeasureType>(),
        }
        this.measureTypes()?.forEach(mt => {
            const dest = usedMtIds.includes(mt.id) ? pass : fail
            dest.items.push(mt)
        })
        return [pass, fail]
    }

    measurementInfo(m: Measurement) {
        const value = m.error_code
            ? m.error_code.name : m.measured_value
                ? formatNumber(m.measured_value, this.locale, NUMBER_FORMAT) : ''

        return [
            `${m.measuretime}: ${value}`,
            m.data_status.constant_name !== 'data_status_standard' ? m.data_status.name : null,
        ]
            .filter(isNotNull)
            .join(', ')
    }

    valueDeviates(v: number | null | undefined, s: History) {
        if (v === null
            || v === undefined
            || (s[0]?.measure_type ?? s[1]?.measure_type)?.constant_name !== 'measure_type_level') return false
        const h = s[0]?.measured_value ?? s[1]?.measured_value
        return h !== null && h !== undefined && (Math.abs(v - h) > MAX_DEVIATION)
    }

    saveRow(row: Unpacked<typeof this.newInputRows>, addNew: boolean) {
        const control = row.formGroup
        control.markAllAsTouched()
        // console.warn(`Saving row`, control.valid)
        // console.warn(control.errors)
        // console.warn(control.value)

        const p = this.project()
        const value = control.getRawValue()
        if (p && control.valid && value.object && value.measureType) {
            if (this.automaticTimestampControl.value) {
                value.datetime = nowAsInputValue()
            }
            const rowValues = {
                comment: value.comment,
                error_code: value.errorCode,
                measure_type: value.measureType,
                measured_value: value.measurement,
                measuretime: value.datetime,
                // No resulting value if error code is set!
                resulting_value: value.errorCode ? null : getResultingValue(value.object, value.measureType, value.measurement),
            }
            console.warn(rowValues)

            const request = row.id === null
                ? this.dataService.createMeasurement(p, value.object, rowValues).pipe(
                    tap(x => {
                        if (x.success) {
                            row.id = x.data.id
                        }
                    }),)
                : this.dataService.updateMeasurementField(p,value.object, {...rowValues, id: row.id}).pipe()
            request.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(ret => {
                if (ret.success) {
                    control.markAsPristine()
                    if (addNew) {
                        this.addRow(value.object)
                    }
                } else {
                    console.warn(ret.error)
                    this.snackBar.open(ret.error, "Dismiss")
                }
            })
        }
        return
    }

    canDeactivate(): boolean {
        return !this.newInputRows.some(r => r.formGroup.dirty)
    }

    ngOnInit(): void {
        this.newInputRows.splice(0)
        this.addRow()
        this.groupControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(g => {
            // console.warn(g)
            this.router.navigate([], {queryParams: {gid: g?.id}})
            if (g) {
                setTimeout(() => {
                    this.maybeSetValue(g)
                })
            }
        })

        const snapshot = this.route.snapshot

        const gid = parseIntegerOrNull(snapshot.queryParamMap.get('gid'))
        if (gid !== null) {
            firstValueFrom(this.dataService.groups$).then(groups => {
                const g = groups.find(g => g.id === gid)
                if (g) {
                    console.log(`Setting group to ${g.name}`)
                    this.groupControl.setValue(g)
                } else {
                    console.warn(`Unknown group id ${gid}`)
                }
            })
        }
    }

    /**
     * If there is just the first control, and it is untouched, set it to the first
     * object in the new group. Set MeasureType to the most recent used type for the
     * object.
     */
    maybeSetValue(g: Group) {
        // console.warn(`maybe set values for group ${g.name}`)
        // const controls = this.rows.controls
        if (this.newInputRows.length === 1) {
            const c = this.newInputRows.at(0)?.formGroup
            if (c?.pristine) {
                // Only 1 row, and it is untouched, go!
                const os = this.activeObjects()
                const o = os.find(o => o.id === g.object_ids[0])
                const mt = this.objectDefaultMeasureType(o)
                c.patchValue({object: o, measureType: mt}, {emitEvent: true})
                return
            }
        }
    }

    showObjectDetails(
        object: VideObject | null,
        measureType: MeasureType | null | undefined,
    ) {
        const p = this.project()
        if (!p || !object) return
        console.log(`show object details for ${object.name}`)
        this.dialog.open<
            ObjectDetailDialogComponent,
            ObjectDetailDialogComponent['data'],
            any>(ObjectDetailDialogComponent, {
            data: {
                project: p,
                object,
                measureType: measureType,
            },
            maxWidth: '100vw',
            height: '90vh',
            panelClass: 'dialog-no-padding',
        })
    }

    locateObject(
        object: VideObject | null,
        group: Group | null,
    ) {
        if (object === null || !objectWithPosition(object)) {
            return
        }
        console.log(`locate object ${object.name}`)
        this.dialog.open<
            LocateObjectDialogComponent,
            LocateObjectDialogData,
            LocateObjectDialogResult
        >(LocateObjectDialogComponent, {
            data: {
                object,
                group,
                objects: this.objects(),
            },
            // width: '80vw',
            maxWidth: '90vw',
            // height: '90vh',
            // closeOnNavigation: true,
            panelClass: 'dialog-no-padding',
        })
    }

    async selectClosestObjectInGroup(
        formGroup: Unpacked<typeof this.newInputRows>['formGroup'],
        group: Group | null,
    ) {
        try {
            const p = this.project()
            if (!p) return

            const currentLocation = await firstValueFrom(this.geolocation)
            const oig = (group ? this.activeObjects().filter(o => group.object_ids.includes(o.id)) : this.activeObjects()).filter(objectWithPosition)
            let closestObject = oig.at(0)

            if (closestObject) {
                let closestDistance = Infinity
                oig.forEach(o => {
                    const dist = getPreciseDistance(currentLocation.coords, o.position, 1)
                    if (dist < closestDistance) {
                        closestDistance = dist
                        closestObject = o
                    }
                })
            }
            formGroup.patchValue({object: closestObject})
        } catch (e) {
            console.error(e)
            this.snackBar.open("Error finding closest object", "Dismiss")
        }
    }

    private addRow(previous?: VideObject | null) {
        const g = this.groupControl.value
        const objects = this.activeObjects()
        let next: number | undefined = undefined
        if (g) {
            if (previous) {
                const idx = g.object_ids.findIndex(id => id === previous.id)
                next = g.object_ids[idx + 1]
            }
            if (next === undefined) {
                next = g.object_ids.at(0)
            }
        }
        const nextObject = objects.find(o => o.id === next)
        const row = this.newRecordRow(nextObject)
        this.newInputRows.push(row)
    }

    private objectDefaultMeasureType(o?: VideObject) {
        if (o && o.statistics.length > 0) {
            return o.statistics
                .reduce((acc, curr) =>
                    acc.last_date_value
                    && curr.last_date_value
                    && acc.last_date_value > curr.last_date_value
                        ? acc
                        : curr)
                .measure_type
        } else {
            const sss = this.measureTypes()
            return sss.find(mt => mt.constant_name === 'measure_type_level') ?? sss.at(0) ?? null
        }
    }

    private newRecordRow(object?: VideObject) {
        const formGroup = this.fb.nonNullable.group({
            object: [object ?? null as VideObject | null, Validators.required],
            measureType: [null as MeasureType | null, Validators.required],
            measurement: [null as number | null],
            errorCode: [null as ErrorCode | null],
            comment: [''], // as string|null ?
            datetime: [nowAsInputValue(), Validators.required],
        }, {validators: [validateInputRow]})
        const aa = formGroup.controls.object
        const bb = formGroup.controls.measureType
        const history =
            combineLatest([
                aa.valueChanges.pipe(startWith(aa.value)),
                bb.valueChanges.pipe(startWith(bb.value)),
                this.dataService.projectNotNull$,
            ]).pipe(
                switchMap(([o, mt, proj]) => {
                    return (o && mt)
                        ? this.dataService.getMeasurements(proj, o, mt,).pipe(
                            map(mr => {
                                const ms = mr.measurements
                                const history: History = [ms.at(-1), ms.at(-2)]
                                return history
                            }),
                        )
                        : of([undefined, undefined] as const)
                }),
            )
        // Auto update measure type on changed object
        formGroup.controls.object.valueChanges.pipe(
            startWith(formGroup.controls.object.value),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(o => {
            if (o) {
                const mt = this.objectDefaultMeasureType(o)
                if (mt) {
                    formGroup.patchValue({measureType: mt})
                }
            }
        })
        return {
            id: null as number | null,
            formGroup,
            history,
        }
    }

    objectLabel(o: VideObject) {
        return [
            o.name,
            o.owner ? `[${o.owner.project_name}]` : null,
            o.readonly ? '(readonly)' : null,
        ].join(' ')
    }

    measureTypeLabel(item: MeasureType, value: VideObject | null) {
        let ret = item.name
        if (value && !value.statistics.some(s => s.measure_type.id === item.id)) {
            ret += ' [Unused]'
        }
        return ret
    }
}

function validateInputRow(c: AbstractControl) {
    const v = c.value as FormValueType
    if (v.measurement === null && v.errorCode === null) {
        return {valueOrError: 'missing value or error code'}
    }
    if (!c.pristine) c.markAllAsTouched()
    return null
}

function parseIntegerOrNull(x: string | null) {
    return x === null ? x : parseInt(x)
}

/**
 * Current timestamp formatted as YYYY-MM-DDTHH:mm, for use in `datetime-local`
 * input element
 */
function nowAsInputValue(): string {
    const now = new Date()
    return now.getFullYear().toString().padStart(4, '0')
        + '-' +
        (now.getMonth() + 1).toString().padStart(2, '0')
        + '-' +
        now.getDate().toString().padStart(2, '0')
        + 'T' +
        now.getHours().toString().padStart(2, '0')
        + ':' +
        now.getMinutes().toString().padStart(2, '0')
}

