import {Injectable} from '@angular/core'

import {Annotations, YAxisName} from "plotly.js"
import {BehaviorSubject, firstValueFrom, map} from "rxjs"

import {DiverInstance, ErrorCode, MeasureType, VideObject} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {PlotlyLayout, PlotlyMouseEvent, VideFigure} from "../../vide-types"
import {VideDataService} from "../../api/vide-data.service"
import {isDefined, PLOTLY_SCATTER_TYPE} from "../../shared/vide-helper"

export interface DiverMeasurement {
    dateTime: string
    value: number
    temp: number
}

export interface DiverData {
    data: readonly DiverMeasurement[]
    head: readonly string[]
    serial: string,
}

export const DIVER_DATA_TYPES = [
    'Uncompensated',
    'Compensated',
    'Referenced',
] as const

export type DiverDataType = typeof DIVER_DATA_TYPES[number]
export type LocalDiverData = {
    measurements: readonly DiverMeasurement[],
    serial: string,
    type: DiverDataType,
}

export type DiverDatum = {
    value: number,
    time_t: number,
    dateTime: string,
}

export function interpolate(a: { x: number, y: number }, b: { x: number, y: number }, x: number) {

    const deltaX = b.x - a.x
    const deltaY = b.y - a.y
    const delta = x - a.x

    return a.y + delta * deltaY / deltaX
}

@Injectable({
    providedIn: 'root'
})
export class DiverService {
    static readonly pressureUnit = 'mH2O'
    // static readonly temperatureUnit = '°C'
    static readonly levelUnit = '+höjd'

    readonly httpStatus = {waiting: 0}
    readonly logs = new LogContainer()

    // /////////////////////////////////////////////////////////
    // // General stuff
    // /////////////////////////////////////////////////////////
    readonly measureTypePressure$ = this.dataService.utility$.pipe(
        map(u => {
            const type = u.measure_type
                .find(t => t.constant_name === 'air_pressure')
            if (!type) {
                throw new Error('No measure type found')
            } else if (type.measure_unit.name !== DiverService.pressureUnit) {
                throw new Error(`Wrong measure type unit ${type.measure_unit.name}`)
            }
            return type
        }),
    )
    readonly measureTypeDiver$ = this.dataService.utility$.pipe(
        map(u => {
            const type = u.measure_type
                .find(t => t.constant_name === 'measure_type_diver')
            if (!type) {
                throw new Error('No measure type found')
            } else if (type.measure_unit.name !== DiverService.levelUnit) {
                throw new Error(`Wrong measure type unit ${type.measure_unit.name}`)
            }
            return type
        }),
    )
    readonly measureTypeReference$ = this.dataService.utility$.pipe(
        map(u => {
            const type = u.measure_type
                .find(t => t.constant_name === 'measure_type_level')
            if (!type) {
                throw new Error('No measure type found')
            } else if (type.measure_unit.name !== DiverService.levelUnit) {
                throw new Error(`Wrong measure type unit ${type.measure_unit.name}`)
            }
            return type
        }),
    )

    constructor(
        private readonly dataService: VideDataService,
    ) {
    }

    async createNewObject(
        name: string,
    ) {
        const p = await firstValueFrom(this.dataService.projectNotNull$)
        const objectType = await firstValueFrom(this.dataService.objectTypeWell$)
        const objectStatus = await firstValueFrom(this.dataService.objectStatusActive$)
        const request = this.dataService.createObject(p, {
            name,
            object_status: objectStatus,
            object_type: objectType,
        })
        this.httpStatus.waiting++
        const result = await firstValueFrom(request).finally(() => {
            this.httpStatus.waiting--
        })
        this.logs.add(result, `Create object ${name}`)
        if (result.success) {
            this.dataService.reloadProjectData()
        }
        return result
    }

    /**
     * Save the data to the object, and on success, reload project data.
     * If errorCode is null, saved as Resulting value, else as Measured value with errorCode.
     *
     * @param data The clipped data
     * @param options
     */
    async saveToObject(
        data: readonly { dateTime: string, value: number }[],
        options: {
            object: Pick<VideObject, "id" | 'name'>,
            measureType: MeasureType,
            errorCode: ErrorCode | null,
            update: boolean,
        },
    ) {
        const p = await firstValueFrom(this.dataService.projectNotNull$)
        const dataStatus = await firstValueFrom(this.dataService.dataStatusStandard$)
        const rows = data.map(x => {
            const base = options.errorCode ? {
                errorCode: options.errorCode,
                measuredValue: x.value
            } : {resultingValue: x.value}
            return ({
                ...base,
                dataStatus,
                measuretime: x.dateTime,
                measureType: options.measureType,
            })
        })
        this.httpStatus.waiting++
        const res = await firstValueFrom(this.dataService.createMeasurements(
            p,
            options.object,
            rows,
            true
        )).finally(() => {
            this.httpStatus.waiting--
        })
        let msg = `Save ${options.measureType.name} measurements to ${options.object.name} `
        if (options.errorCode) {
            msg += ` as ${options.errorCode?.name}`
        }
        this.logs.add(res, msg)
        if (res.success) {
            this.dataService.reloadProjectData()
        } else {
        }
        return res
    }

    async deleteInstance(element: DiverInstance) {
        const p = await firstValueFrom(this.dataService.projectNotNull$)
        const res = await firstValueFrom(this.dataService.deleteDiverInstance(p, element, {deleteMeasurements: false}))
        if (!res.success) {
            this.logs.add(res, 'Delete diver installation')
        }
    }

}

/**
 * Parses a file as from DiverOffice. Expects data to be in mH2O and degrees celsius.
 * @param text
 */
export function parseDiverOfficeData(text: string,): DiverData | null {
    const rows = text.split("\r\n")
    const length = rows.length
    let idx = 0
    while (idx < length && rows.at(idx) !== '[Logger settings]') {
        ++idx
    }
    let match
    while (idx < length && (match = rows.at(idx)?.match(/ {2}Serial number +=(.+)/)) === null) {
        ++idx
    }
    if (!match) {
        throw new Error('No serial number found')
    }
    const serial = match.at(1)!
    const headerM = 'Date/time;Pressure[mH2O];Temperature[°C]'
    const headerCm2 = 'Date/time;Pressure[cmH2O];Temperature[°C]'
    const headerCm = 'Date/time;Water head[cm];Temperature[°C]'

    let current
    while (idx < length && !((current = rows.at(idx)!).startsWith('Date/time;'))) {
        ++idx
    }
    if (idx === length) {
        // Found no data header, maybe the format is different, and we should handle this too?
        throw new Error('No header row found, maybe the format is unknown')
    }
    let factor = 1
    switch (current) {
        case headerM:
            break
        case headerCm:
        case headerCm2:
            factor = 0.01
            break
        default:
            throw new Error(`Unknown header row ${current}`)
    }
    const head = rows.splice(0, idx + 1)

    const data = []
    for (const row of rows) {
        if (row === "END OF DATA FILE OF DATALOGGER FOR WINDOWS") break
        const [dateTime, value, temp] = row.split(';')
        if (!dateTime || !value || !temp) throw new Error(`Cannot parse row ${row}`)
        const match = dateTime.match(/^(?<year>\d{4})\/(?<month>\d{2})\/(?<date>\d{2}) (?<time>\d{2}:\d{2}:\d{2})/)
        if (!match?.groups) throw new Error(`Cannot parse date-time ${dateTime}`)
        const year = match.groups['year']!
        const month = match.groups['month']!
        const date = match.groups['date']!
        const time = match.groups['time']!
        data.push({
            dateTime: `${year}-${month}-${date} ${time}`,
            value: factor * parseFloatComma(value),
            temp: parseFloatComma(temp),
        })
    }

    return {serial, data, head}
}

export function readAsText(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ev => {
            const result = reader.result
            if (typeof result === 'string') {
                resolve(result)
            } else {
                reject('Type error')
            }
        }
        reader.readAsText(file, 'ISO-8859-1')
    })
}

export function annotationsToAnnotations(as: Array<Partial<Annotations>> & {}) {
    if (as.length === 0) {
        return []
    }
    const a = as.at(0)
    const b = as.at(1)
    const [begin, end] = (a && b && a.x && b.x && a.x > b.x) ? [b, a] : [a, b]
    if (begin) {
        begin.text = 'begin'
    }
    if (end) {
        end.text = 'end'
    }
    return [begin, end].filter(isDefined)
}

function parseFloatComma(str: string) {
    switch (countChar(',', str)) {
        case 1:
            return parseFloat(str.replace(',', '.'))
        case 0:
            return parseFloat(str)
        default:
            throw new Error('String has more than one comma')
    }
}

function countChar(char: string, string: string) {
    let finds = 0
    for (let i = 0; i < string.length; i++) {
        if (char === string.charAt(i)) finds++
    }
    return finds
}

export function getFigure(
    measurements: readonly DiverMeasurement[],
    name: string,
    annotations: Partial<Annotations>[],
) {
    const figure: VideFigure = {
        data: [
            {
                x: measurements.map(z => z.dateTime),
                y: measurements.map(z => z.value),
                yaxis: 'y',
                mode: 'lines+markers',
                type: PLOTLY_SCATTER_TYPE,
                name: name,
            },
            {
                x: measurements.map(z => z.dateTime),
                y: measurements.map(z => z.temp),
                yaxis: 'y2',
                mode: 'lines+markers',
                type: PLOTLY_SCATTER_TYPE,
                name: 'temperature',
            }

        ],
        layout: {
            autosize: true,
            yaxis: {
                title: 'meter H₂O',
            },
            yaxis2: {
                title: '°C',
                overlaying: 'y',
                side: 'right',
            },
            annotations: annotations,
        },
        config: {
            // showLink: true, // text link on plot
            showEditInChartStudio: true, // icon in modebar
            plotlyServerURL: "https://chart-studio.plotly.com",
            // showLink: true,
            // linkText: 'This text is custom!' + imageSize?.width + 'x' + imageSize?.height,
            modeBarButtonsToRemove: [
                // 'pan2d',
                // 'zoom2d',
                'select2d',
                'lasso2d',
                'resetScale2d',
            ],
            modeBarButtonsToAdd: [
                'toggleSpikelines',
                // 'zoom2d',
            ],
            // Skip the size options, we use the default, the current plot size.
            // The size option is for the manual save button.
            // toImageButtonOptions: {filename: value.toImage.filename},
        }

    }
    return figure
}

export function getFilterFn<T extends Pick<DiverMeasurement, 'dateTime'>>(annotations: readonly Partial<Annotations>[]) {
    const begin = annotations?.at(0)?.x
    const end = annotations?.at(1)?.x
    const filters = [
        begin ? ((x: T) => begin <= x.dateTime) : undefined,
        end ? ((x: T) => x.dateTime <= end) : undefined,
    ].filter(isDefined)
    return (x: T) => filters.every(f => f(x))
}

export function figureClick(subject: BehaviorSubject<NonNullable<PlotlyLayout['annotations']>>, event: PlotlyMouseEvent) {
    const p = event.points.at(0)
    if (!p) return

    const x = p.x
    const y = p.y
    if (!x || !y) {
        return
    }
    if (x instanceof Date || y instanceof Date) {
        return
    }
    // TODO: make changes to DT package: add Date to x and y type
    const annotation: NonNullable<PlotlyLayout['annotations']>[number] = {
        x,
        y,
        // Types defined differently in different places...
        yref: p.data.yaxis as YAxisName,
    }
    const value = subject.value
    if (value.length > 1) {
        value.shift()
    }
    value.push(annotation)
    subject.next(value)
}
