<div class="warning">
    Warning: Beta functionality, not thoroughly tested
</div>

<mat-card>
    <mat-card-header>
        <mat-card-title>Handle diver data</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p>
            All steps, except selection a diver object, are optional and can be performed independently of each other.
            The order indicates a common workflow.
        </p>
        <p>
            Saving is optional for the diver measurement steps. After saving, it is possible to leave the process and
            continue at a later time.
        </p>
    </mat-card-content>
</mat-card>

<mat-stepper [linear]="true" orientation="vertical">

    <!--    &lt;!&ndash;  Secret buttons used to navigate programmatically.  &ndash;&gt;-->
    <!--    <button mat-button matStepperPrevious type="button">Previous</button>-->
    <!--    <button mat-button #next matStepperNext type="button">Next</button>-->

    <mat-step label="Import Baro-Diver measurmenets" [completed]="baroCompleted.getValue()">
        <ng-template matStepContent>
            <app-import-baro
                (completed)="baroCompleted.next($event)"
                (object)="selectedBaro.next($event)"
            />
        </ng-template>
    </mat-step>

    <!--        [hasError]="!selectedObject.getValue()"-->
    <!--        [stepControl]="form"-->
    <mat-step
        [completed]="selectedObject.getValue()"
        label="Select object for diver data"
        errorMessage="Object is required"
    >
        <!--        <ng-template matStepContent>-->
        <!--        </ng-template>-->
        <app-select-diver-object
            (object)="selectedObject.next($event)"
        />
    </mat-step>

    <mat-step label="Import Diver measurements" optional>
        @if (selectedObject.getValue(); as o) {
            <ng-template matStepContent>
                <app-import-diver
                    [diverObject]="o"
                    (data)="localDiverData.next($event)"
                />
            </ng-template>
        }
    </mat-step>

    <mat-step label="Compensate measurements" optional>
        <ng-template matStepContent>
            @if (selectedObject.getValue(); as o) {
                <app-compensate-diver
                    [diverObject]="o"
                    [baroObject]="selectedBaro.getValue()"
                    [localDiverData]="localDiverData.getValue()"
                    (data)="localCompensatedData.next($event)"
                />
            }
        </ng-template>
    </mat-step>

    <mat-step label="Reference measurements" optional>
        @if (selectedObject.getValue(); as o) {
            <ng-template matStepContent>
                <app-reference-diver
                    [diverObject]="o"
                    [localDiverData]="localDiverData.getValue()"
                    [localCompensatedData]="localCompensatedData.getValue()"
                />
            </ng-template>
        }
    </mat-step>

    <mat-step label="Existing installations" optional>

        @if (selectedObject.getValue(); as o) {
            <app-existing-instances
                [diverObject]="o"
            />
        }

    </mat-step>
</mat-stepper>

@if (httpStatus.waiting > 0) {
    <mat-progress-bar
        mode="indeterminate"
    />
}

<mat-card>
    <app-log-list [container]="logs"/>
</mat-card>
