import * as S from 'superstruct'

import {ExternalObject, Position} from "../../api/api-types"

const CRSProperties = S.object({
    name: S.string(),
})

const CRS = S.object({
    type: S.string(),
    properties: CRSProperties,
})

const Geometry = S.object({
    type: S.literal("Point"),
    coordinates: S.tuple([S.number(), S.number()]),
})
const Jordart = S.enums([
    "sand",
    "morän",
    "grus eller grövre",
    "jord saknas",
    "varvig lera",
    "lera, ej varvig",
    "torv",
    "moränlera",
    "silt, ej varvig",
    "silt, varvig/ej varvig",
    "lera, varvig/ej varvig",
])

const Akvifertyp = S.enums([
    "berg , öppet magasin",
    "jord , öppet magasin",
    "berg , slutet magasin",
    "jord , slutet magasin",
    "okänt om magsinet är jord eller berg , okänd typ av magasin",
    "berg , okänd typ av magasin",
    "jord , okänd typ av magasin",
    "kombinerat jord och berg , öppet magasin",
])

const TopografisktLage = S.enums([
    "inströmningsområde",
    "intermediärt område",
    "inströmningområde - vattendelarläge",
    "utströmningsområde"
])

const GrundvattenstationProperties = S.object({
    "omrade-_och_stationsnummer": S.string(),
    stationens_namn: S.string(),
    startdatum_for_matning: S.string(),//Date
    jordart: S.optional(Jordart),
    akvifertyp: S.optional(Akvifertyp),
    topografiskt_lage: S.optional(TopografisktLage),
    "referensniva_for_roroverkant_m.o.h.": S.optional(S.string()),
    rorhojd_ovan_mark_m: S.optional(S.string()),
    total_rorlangd_m: S.optional(S.string()),
    kommunkod: S.string(),
    eucd_far_grundvattenforekomst: S.optional(S.string()),
    slutdatum_for_matning: S.optional(S.string()),//Date
    nivamatningskvalitet: S.optional(S.string()),
})
type GrundvattenstationProperties = S.Infer<typeof GrundvattenstationProperties>

const Grundvattenstation = S.object({
    type: S.literal("Feature"),
    geometry: S.nullable(Geometry),
    properties: GrundvattenstationProperties,
})
export const SguGrundvattenstationer = S.object({
        type: S.string(),
        crs: CRS,
        features: S.array(Grundvattenstation),
    }
)

export function sguGetObjectId(o: Pick<SguObject, 'properties'>): string {
    return o.properties['omrade-_och_stationsnummer']
}

export function sguGetObjectName(o: Pick<SguObject, 'properties'>): string {
    return o.properties.stationens_namn
}

export interface SguObject {
    position: Position | null
    positionM: { x: number, y: number } | null
    properties: GrundvattenstationProperties
    external: ExternalObject | null
    tainted:boolean
}
