import {AfterViewInit, Component, OnDestroy, viewChild, ViewChild} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {map} from "rxjs"
import {PlotlyComponent, PlotlyViaWindowModule} from 'angular-plotly.js'

import {PlotlyMouseEvent} from 'src/app/vide-types'
import {PLOT_CONFIG,} from 'src/constants'
import {sguGetObjectId, sguGetObjectName, SguObject} from './sgu-types'
import {SGU_SWEDISH_LAN, SguService} from './sgu.service'
import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {LogListComponent} from "../../log-list/log-list.component"
import {moveMenu} from "../../shared/mat-menu"
import {JsonPipe} from "@angular/common"

@Component({
    selector: 'app-sgu',
    standalone: true,
    templateUrl: './sgu.component.html',
    imports: [
        LogListComponent,

        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
        JsonPipe,
    ],
    styleUrls: ['./sgu.component.scss']
})
export class SguComponent extends ComponentCanDeactivate implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    protected readonly SWEDISH_LAN = SGU_SWEDISH_LAN
    protected readonly figure = toSignal(this.dataService.figure$)
    protected readonly form = this.dataService.form
    protected readonly inProgress = this.dataService.inProgress
    protected readonly logs = this.dataService.logs
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly stationsToShow$ = this.dataService.stationsToShow$
    protected readonly trigger = viewChild(MatMenuTrigger)

    protected readonly objectWithoutCoordinatesExists = toSignal(this.dataService.stationsToShow$.pipe(
        map(objects => objects.some(o => o.position === null))
    ))
    protected readonly displayedColumns = [
        'name',
        'active',
        'from',
        'to',
        'imported',
        'action',
        // 'json',
    ] as const

    constructor(
        private dataService: SguService,
    ) {
        super()
    }

    protected readonly sguGetObjectName = sguGetObjectName

    add = this.dataService.add.bind(this.dataService)
    onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)
    remove = this.dataService.remove.bind(this.dataService)

    override canDeactivate() {
        return true
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.dataService.plotlyHost = undefined
    }

    ngAfterViewInit(): void {
        this.dataService.plotlyHost = this
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const custom = event.points[0]?.customdata as SguObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()
        if (trigger) {
            trigger.menuData = {element: custom,}
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }

}
