import {Injectable} from '@angular/core'
import {FormControl} from "@angular/forms"

import {
    combineLatest,
    combineLatestWith,
    filter,
    finalize,
    firstValueFrom,
    map,
    shareReplay,
    switchMap,
    tap
} from "rxjs"
import * as S from "superstruct"

import {omit, PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {PLOT_COLOR} from "../../../constants"
import {ExternalObject, Position, PositionM, UnosonLocation, VideObject} from "../../api/api-types"
import {LogContainer} from "../../log-list/log-container"
import {hasNoPosition, hasPosition} from "../../shared/vide-helper"

const ExternalObjectUnoson = S.assign(ExternalObject, S.object({
    options: S.object({
        locationId: S.string(),
        // the rest are not used, but delivered... maybe we should stop them in the backend resource?
        levelTagId: S.string(),
        waterColumnTagId: S.nullable(S.string()),
        connectionName: S.string(),
        updated: S.integer(), // unix time
    })
}))
type ExternalObjectUnoson = S.Infer<typeof ExternalObjectUnoson>

export type XLocation = {
    location: Omit<UnosonLocation, 'x' | 'y'>,
    position: Position | null,
    positionM: PositionM | null,
    attached: VideObject | null,
}

/**
 * If we use another source than Unoson in the future, maybe this should handle all types?
 * Or we should use another service in the component for that case.
 */
@Injectable({
    providedIn: 'root'
})
export class UnosonService extends AbstractMapboxService {

    readonly sources$ = this.dataService.projectNotNull$.pipe(
        map(p => p.unoson_sources),
        tap(sources => {
            this.sourceForm.setValue(sources.at(0) ?? '')
        }),
    )
    readonly sourceForm = new FormControl<string>('', {nonNullable: true})
    // readonly selectedIdForm = new FormControl<string[]>([], {nonNullable: true})
    private readonly ExternalType = 'unoson' as const

    protected override useColorbar: boolean = false

    readonly httpStatus = {total: 0, requested: 0, fetched: 0}
    readonly logs = new LogContainer('Save')
    // private readonly selectionModel = new SelectionModel<string>(true, [], true)

    readonly externalSelection$ = this.sourceForm.valueChanges.pipe(
        filter(name => name.length > 0),
        combineLatestWith(this.dataService.projectNotNull$),
        switchMap(([name, p]) => this.dataService.getExternalObjects(p, this.ExternalType, name)),
        map(externals => S.create(externals, S.array(ExternalObjectUnoson))),
        shareReplay({refCount: true, bufferSize: 1}),
        tap(x => {
            console.warn(x)
        })
    )

    readonly locations$ = this.dataService.projectNotNull$.pipe(
        combineLatestWith(this.sourceForm.valueChanges),
        tap(x => {
            this.httpStatus.total++
        }),
        switchMap(([p, name]) => this.dataService.getImportUnosonLocations(p, name).pipe(
            finalize(() => {
                this.httpStatus.total--
            })
        )),
        combineLatestWith(this.dataService.getConverter(3011)),
        map(([locations, converter]) => {
            return locations.map(l1 => {
                const l = l1.location
                let position
                let positionM
                if (l.x === '0' && l.y === '0') {
                    position = null
                    positionM = null
                } else {
                    const [lon, lat] = [Number.parseFloat(l.y), Number.parseFloat(l.x)] as const
                    const [x, y] = converter.inverse([lon, lat])
                    position = {lat, lon}
                    positionM = {x, y}
                }
                const ret: XLocation = {
                    location: omit(l, 'x', 'y'),
                    position,
                    positionM,
                    attached: null as null | VideObject,
                }
                return ret
            })
        }),
        combineLatestWith(this.externalSelection$, this.dataService.objects$),
        map(([locations, external, objects]) => {
            console.warn('New external selection for locations$', external)
            return locations.map(l => {
                const ex = external.find(x => x.options.locationId === l.location.id)
                const object = objects.find(o => o.id === ex?.object_id) ?? null
                l.attached = object
                return l
            })
        }),
        shareReplay({refCount: true, bufferSize: 1}),
        // tap(x => {
        //     console.warn('locations$', x)
        // })
    )
    private readonly locationsWithCoordinates$ = this.locations$.pipe(
        map(locations => locations.filter(hasPosition))
    )
    // readonly locationsNoCoordinates$ = this.locations$.pipe(
    //     map(locations => locations.filter(hasNoPosition))
    // )
    readonly locationsWithoutCoordinatesExists$ = this.locations$.pipe(
        map(locations => locations.some(hasNoPosition))
    )
    // private readonly locations$ = this.dataService.projectNotNull$.pipe(switchMap(p => this.dataService.getImportUnosonLocations(p)))
    public readonly figure$ = combineLatest([
        this.locationsWithCoordinates$,
        this.externalSelection$,
        this.forceRelayout$,
        // this.selectionModel.changed.pipe(startWith(null)),
        // this.selectedIdForm.valueChanges.pipe(startWith([])),
    ]).pipe(
        map(([locations,]) => {
            console.log(locations)
            const color = locations.map(l =>
                    l.attached ? PLOT_COLOR.object_selected : PLOT_COLOR.object_unselectd
                // this.isSelected(l.location.id)
                //     ? PLOT_COLOR.object_selected
                //     : PLOT_COLOR.object_unselectd
            )
            const lat = locations.map(l => {
                return l.position.lat
            })
            const lon = locations.map(l => {
                return (l.position.lon)
            })
            const text = locations.map(l => {
                return l.location.description
            })
            // Strange type in @types/plotly, it can be any[]
            const customdata = locations as any[]
            // const customdata = locations.map(l => {
            //     return l.location.id
            // })
            const hovertemplate = locations.map(l => {
                const desc = this.getDescription(l.location)
                return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
            })
            const data: PlotlyScattermapboxData = {
                type: 'scattermapbox',
                lat,
                lon,
                text,
                customdata,
                hovertemplate,
                marker: {color},

            }
            const ret: VideFigure = {
                config: this.getMapConfig(),
                layout: this.getLayout(locations, {background: 'open-street-map'}),
                // layout: this.getLayout(positions, {background: 'open-street-map'}),
                data: [data]
            }
            return ret
        }),
        tap(x => {
            console.warn(x)
        }),
    )

    getDescription(l: any) {
        const desc: string[] = []
        {
            let key: keyof typeof l
            for (key in l) {
                if (key === 'position') continue
                if (key === 'positionM') continue
                desc.push(`${key}: ${l[key]}`)
            }
        }
        return desc
    }

    constructor(
        // private readonly dataService: VideDataService,
    ) {
        super()
    }

    // select(ids: string[]) {
    //     // this.selectionModel.setSelection(...ids)
    //
    //     const control = this.selectedIdForm
    //     const selected = control.value
    //     selected.push(...ids)
    //     control.markAsDirty()
    //     control.patchValue(selected)
    // }

    // toggle(id: string) {
    //     // console.log(id)
    //     // this.selectionModel.toggle(id)
    //     const control = this.selectedIdForm
    //     const selected = control.value
    //     const idx = selected.indexOf(id)
    //     if (idx === -1) {
    //         selected.push(id)
    //     } else {
    //         selected.splice(idx, 1)
    //     }
    //     control.markAsDirty()
    //     control.patchValue(selected)
    // }

    // reset() {
    //     firstValueFrom(this.externalSelection$).then(xs => {
    //         this.setSelection(xs)
    //     })
    // }

    // setSelection(xs: readonly ExternalObjectUnoson[]) {
    //     const ids = xs.map(x => x.options.locationId)
    //     this.selectedIdForm.setValue(ids)
    //     this.selectedIdForm.markAsPristine()
    // }

    async deAttach(locationId: string) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const existing = await firstValueFrom(this.externalSelection$)
        const x = existing.find(x => x.options.locationId === locationId)
        console.log(x)
        if (!x) return
        const res = await firstValueFrom(this.dataService.deleteExternalSource(project, x))
        this.logs.add(res, `Remove unoson source ${locationId}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async attach(locationId: string, object: Pick<VideObject, 'id'> | string) {
        const body = typeof object === 'string' ? {createName: object} : {objectId: object.id}
        const connectionName = this.sourceForm.value
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const res = await firstValueFrom(this.dataService.createExternalObject(
            project,
            this.ExternalType,
            {
                locationId,
                connectionName,
                ...body,
            }))
        // const res = await firstValueFrom(this.dataService.createUnoson(project, object, locationId, connectionName))
        this.logs.add(res, `Attach ${locationId}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    // async save() {
    //     console.log("Will save selected stations...")
    //     const project = this.dataService.project()
    //     const name = this.sourceForm.value
    //     if (!project || name === '') {
    //         return
    //     }
    //     const locations = await firstValueFrom(this.locations$)
    //     const selected = locations.filter(f => this.isSelected(f.id))
    //
    //     const existing = await firstValueFrom(this.externalSelection$)
    //     const removed = existing.filter(x => !this.isSelected(x.options.locationId))
    //     const existingIds = existing.map(x => x.options.locationId)
    //     const added = selected.filter(f => !existingIds.includes(f.id))
    //     this.httpStatus.total = added.length + removed.length
    //     if (this.httpStatus.total < 1) {
    //         console.log(`No changes to save`)
    //         return
    //     }
    //     this.httpStatus.requested = 0
    //     this.httpStatus.fetched = 0
    //     let someFailure = false
    //     let someSuccess = false
    //     const addRequests = added.map(s => {
    //         const properties = {locationId: s.id, connectionName: name}
    //         return this.dataService.createExternalObject(project, this.ExternalType, properties).pipe(
    //             tap(res => {
    //                 // this.httpStatus.requested++
    //                 this.httpStatus.fetched++
    //                 someFailure ||= !res.success
    //                 someSuccess ||= res.success
    //                 this.logs.add(res, `Create ${s.name}`)
    //             }),
    //         )
    //     })
    //     const removeRequests = removed.map(x => this.dataService.deleteExternalSource(project, x).pipe(
    //         tap(res => {
    //             // this.httpStatus.requested++
    //             this.httpStatus.fetched++
    //             someFailure ||= !res.success
    //             someSuccess ||= res.success
    //             const name = locations.find(l => (l.id === x.options.locationId))?.name
    //             this.logs.add(res, `Delete ${name}`)
    //         }),
    //     ))
    //     this.httpStatus.requested += addRequests.length
    //     this.httpStatus.requested += removeRequests.length
    //     const subscription = concat(...addRequests, ...removeRequests)
    //         .subscribe({
    //             complete: () => {
    //                 if (!someFailure) {
    //                     this.httpStatus.total = 0
    //                     console.log(`all ok, reloading project data`)
    //                 }
    //                 if (someSuccess) {
    //                     this.dataService.reloadProjectData()
    //                 }
    //                 subscription.unsubscribe()
    //             },
    //         })
    // }

    // isSelected(id: string) {
    //     return this.selectedIdForm.value.includes(id)
    // }
}
