@if (figure(); as plot) {
    <plotly-plot
        [data]="plot.data"
        [layout]="plot.layout"
        [config]="plot.config"
        [useResizeHandler]="true"
        [style]="plotlyStyle()"
    />
}

@if (form.controls; as cntrl) {
    <mat-expansion-panel expanded class="vide-form-field">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Options
            </mat-panel-title>
        </mat-expansion-panel-header>

        @if (problemMessage(); as message) {
            <div class="warning"> {{ message }}</div>
        }

        <div class="vide-columns-wide vide-form-field-row">
            <ng-select [items]="objects()" [formControl]="cntrl.base_object" [clearable]="false">
                <ng-template ng-label-tmp let-item="item">
                    Base object: {{ item.name }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                </ng-template>
            </ng-select>

            <ng-select [items]="groupedCorrelations()??null" [formControl]="cntrl.correlation" groupBy="items"
                       [clearable]="false">
                <ng-template ng-optgroup-tmp let-item="item">
                    <b> {{ item.title }}</b>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    Reference: {{ item.ref_object.name }}–{{ item.ref_measure_type.name }}
                    ({{ item.pred_ic | number : NUMBER_FORMAT }})
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item.ref_object.name }}–{{ item.ref_measure_type.name }}
                    ({{ item.pred_ic | number : NUMBER_FORMAT }})
                </ng-template>
            </ng-select>
        </div>
        <!-- Markers -->
        @if (cntrl.yaxis.controls.markers.controls; as ctlM) {
            <div class="vide-columns vide-form-field-row">
                Markers:
                <mat-slide-toggle [formControl]="ctlM.allMeasurements">All measurements</mat-slide-toggle>
                <mat-slide-toggle [formControl]="ctlM.nonChecked">Not checked</mat-slide-toggle>
                <mat-slide-toggle [formControl]="ctlM.affected">Affected</mat-slide-toggle>
                <mat-slide-toggle [formControl]="ctlM.correlationStatus">Correlation status</mat-slide-toggle>
            </div>
        }

        <mat-action-row>
            <button mat-raised-button (click)="resetPlotOptions()" matTooltip="Reset options">
                <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
            </button>
        </mat-action-row>
    </mat-expansion-panel>
}
