import {Injectable} from '@angular/core'
import {SelectionModel} from "@angular/cdk/collections"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import * as S from "superstruct"
import {combineLatest, combineLatestWith, concat, filter, firstValueFrom, map, startWith, switchMap, tap} from "rxjs"

import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {PLOT_COLOR} from "../../../constants"
import {PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {TypedHttpService} from "../typed-http.service"
import {LogContainer} from "../../log-list/log-container"
import {isDefined} from "../../shared/vide-helper"
import {ExternalObject} from "../../api/api-types"

const GeoarkivetFeature = S.object({
        type: S.literal("Feature"),
        id: S.string(),// "groundwater.8738",
        geometry: S.object({
            type: S.literal("Point"),
            /** [x, y, z] */
            coordinates: S.tuple([S.number(), S.number(), S.number()]),
        }),
        geometry_name: S.literal("PointGeometry"),
        properties: S.object({
            Id: S.integer(),// 8738,
            GroundInvestigationId: S.integer(),// 198,
            GroundInvestigationName: S.string(),// "Miljöstation, Globen",
            GroundInvestigationResponsibleOrganizationName: S.nullable(S.string()),//"Vägverket",
            BoreHoleInvestigationDate: S.nullable(S.never()),// null,
            Name: S.string(),// "97A131",
            ObjectType: S.literal('Grundvattenrör'),
            ProtocolData_ObservationDate_Min: S.nullable(S.string()),// "1964-10-14",
            ProtocolData_ObservationDate_Max: S.nullable(S.string()),// "1964-10-14",
            ProtocolData_Num: S.nullable(S.integer()),//2,
            Position_N_Rounded: S.integer(),// 6575389,
            Position_E_Rounded: S.integer(),// 154949,
            Position_Z: S.number(),//42.272,
            PlanSymbolImage_GeoDocumentId: S.integer(),//30445,
            ProfileImage_GeoDocumentId: S.integer(),//30446,
            ProfileDocument_GeoDocumentId: S.integer(),//30447
        }),
        /** [xMin, yMin, xMax, yMax] */
        bbox: S.tuple([S.number(), S.number(), S.number(), S.number()]),
    },
)
type GeoarkivetFeature = S.Infer<typeof GeoarkivetFeature>
const GeoarkivetBase = S.object({
    type: S.literal('FeatureCollection'),
    features: S.array(GeoarkivetFeature),
    crs: S.object({
        type: S.literal('name'),
        properties: S.object({
            name: S.literal("urn:ogc:def:crs:EPSG::3011"),
        })
    }),
    bbox: S.tuple([S.number(), S.number(), S.number(), S.number()]),
    totalFeatures: S.integer(),
    numberMatched: S.integer(),
    numberReturned: S.integer(),
    timeStamp: S.string(), //"2024-03-26T13:06:12.867Z"
})
const ExternalObjectGeoarkivet = S.assign(ExternalObject, S.object({
    options: S.object({
        id: S.integer(),
        // the rest are not used, but delivered... maybe we should stop them in the backend resource?
        updated: S.unknown(),
        // factor: S.unknown(),
    })
}))

@Injectable({
    providedIn: 'root'
})
export class GeoarkivetService extends AbstractMapboxService {
    /**
     * See
     * http://opengeospatial.github.io/e-learning/wfs/text/operations.html
     * for more info on searching and query in the request
     * @private
     */
    private readonly baseUrl = 'https://openexpl.stockholm.se/geoserver/geoarchive/wfs?SERVICE=WFS&REQUEST=GetFeature&typeName=groundwater&outputFormat=json'
    private readonly ExternalType = 'geoarkivet'
    private readonly selectionModel = new SelectionModel<number>(true, [], true)

    protected override useColorbar: boolean = false

    readonly logs = new LogContainer('Save')
    readonly httpStatus = {total: 0, requested: 0, fetched: 0}

    readonly converter3011$ = this.dataService.getConverter(3011)
    readonly features$ = this.http.getTyped(this.baseUrl, GeoarkivetBase).pipe(
        map(base => base.features),
    )
    readonly figure$ = combineLatest([
        this.features$,
        this.converter3011$,
        this.selectionModel.changed.pipe(startWith(true)),
    ]).pipe(
        map(([features, converter]) => {
            const positions = features.map(o => {
                const [x, y] = o.geometry.coordinates
                const [lon, lat] = converter.forward([x, y])
                return {
                    position: {lat, lon},
                    positionM: {x, y},
                    // latitude: lat,
                    // longitude: lon,
                    // ew: x,
                    // ns: y
                }
                // return {position: {lat, lon, x, y}}
            })
            const text = features.map(o => o.properties.Name)
            const ids = features.map(o => o.properties.Id.toString())
            const color = features.map(o =>
                this.isSelected(o)
                    ? PLOT_COLOR.object_selected
                    : PLOT_COLOR.object_unselectd
            )
            const objectTrace: PlotlyScattermapboxData = {
                type: 'scattermapbox',
                lat: positions.map(p => p.position.lat),
                lon: positions.map(p => p.position.lon),
                text,
                ids,
                marker: {
                    color,
                },
                hovertemplate: features.map(o => {
                    const p = o.properties
                    const desc = []
                    {
                        let key: keyof typeof p
                        for (key in p) {
                            desc.push(`${key}: ${p[key]}`)
                        }
                    }
                    return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
                }),
            }
            const layout = this.getLayout(positions, {background: 'open-street-map'})
            const config = this.getMapConfig({filename: 'asdf'})
            const figure: VideFigure = {
                config,
                data: [objectTrace],
                layout,
            }
            return figure
        })
    )
    readonly selected$ = this.features$.pipe(
        combineLatestWith(this.selectionModel.changed),
        map(([features, selectionChange]) => {
            return features.filter(f => selectionChange.source.isSelected(f.properties.Id))
        })
    )
    readonly externalSelection$ = this.dataService.project$.pipe(
        filter(isDefined),
        switchMap((p) => this.dataService.getExternalObjects(p, this.ExternalType)),
        map(externals => S.create(externals, S.array(ExternalObjectGeoarkivet))),
    )
    readonly project = this.dataService.project

    constructor(
        private readonly http: TypedHttpService,
        // private readonly dataService: VideDataService,
    ) {
        super()
        this.externalSelection$.pipe(takeUntilDestroyed()).subscribe(xs => {
            const ids = xs.map(x => x.options.id)
            this.selectionModel.setSelection(...ids)
        })
    }

    private isSelected(o: GeoarkivetFeature) {
        return this.selectionModel.isSelected(o.properties.Id)
    }

    select(ids: string[]) {
        this.selectionModel.select(...ids.map(id => parseInt(id)))
    }

    toggle(id: string) {
        this.selectionModel.toggle(parseInt(id))
    }

    async save() {
        // console.log("Will save selected stations...")
        const project = this.project()
        if (!project) {
            return
        }
        const features = await firstValueFrom(this.features$)
        const selected = features.filter(f => this.selectionModel.isSelected(f.properties.Id))

        const existing = await firstValueFrom(this.externalSelection$)
        const existingIds = existing.map(x => x.options.id)
        const added = selected.filter(f => !existingIds.includes(f.properties.Id))
        const removed = existing.filter(x => !this.selectionModel.isSelected(x.options.id))
        this.httpStatus.total = added.length + removed.length
        this.httpStatus.requested = 0
        this.httpStatus.fetched = 0
        let someFailure = false
        let someSuccess = false
        if (this.httpStatus.total < 1) {
            console.log(`No changes to save`)
            return
        }
        const addRequests = added.map(s => {
            const properties = {id: s.properties.Id}
            return this.dataService.createExternalObject(project, this.ExternalType, properties).pipe(
                tap(res => {
                    // this.httpStatus.requested++
                    this.httpStatus.fetched++
                    someFailure ||= !res.success
                    someSuccess ||= res.success
                    this.logs.add(res, `Create ${s.properties.Name}`)
                }),
            )
        })
        const removeRequests = removed.map(x => this.dataService.deleteExternalSource(project, x).pipe(
            tap(res => {
                // this.httpStatus.requested++
                this.httpStatus.fetched++
                someFailure ||= !res.success
                someSuccess ||= res.success
                const name = features.find(s => (s.properties.Id === x.options.id))?.properties.Name
                this.logs.add(res, `Delete ${name}`)
            }),
        ))
        this.httpStatus.requested += addRequests.length
        this.httpStatus.requested += removeRequests.length
        const subscription = concat(...addRequests, ...removeRequests)
            .subscribe({
                complete: () => {
                    if (!someFailure) {
                        // if (addRequests.length > 0) {
                        //     this.logs.addPlainMessage(reloadMessage)
                        // }
                        // this.form.markAsPristine()
                        this.httpStatus.total = 0
                        console.log(`all ok, reloading project data`)
                        this.dataService.reloadProjectData()
                    } else {
                        console.warn(`Some failures, not reloading project data`)
                    }
                    if (someSuccess) {
                        this.dataService.reloadProjectData()
                    }
                    subscription.unsubscribe()
                },
            })
    }

    reset() {
        console.log("Will reset to original values...")
        firstValueFrom(this.externalSelection$).then(xs => {
            const ids = xs.map(x => x.id)
            this.selectionModel.setSelection(...ids)
        })

    }

}
