<mat-card>
    <mat-card-header>
        <mat-card-title> Stockholms stad Geoarkivet</mat-card-title>
        <!--        <mat-card-subtitle></mat-card-subtitle>-->
        <!-- <img mat-card-avatar> -->
    </mat-card-header>
    <mat-card-content>
        <!-- Primary card content (adds padding) -->
        <p> Selected stations will be added to the project. New water level measurements will be added nightly. </p>
        <p> Data provided from <a href="https://etjanster.stockholm.se/geoarkivet/">Geoarkviet</a>. </p>

        @if (figure(); as figure) {
            <plotly-plot
                [data]="figure.data"
                [layout]="figure.layout"
                [config]="figure.config"
                [useResizeHandler]="true"
                [style]="plotlyStyle"
                (selected)="plotlySelected($event)"
                (plotlyClick)="plotlyClick($event)"
                (relayout)="onPlotlyRelayout($event)"

            />
        } @else {
            <div [style.height]="plotlyStyle.height" class="plot-spinner">
                <mat-spinner [style.height]="plotlyStyle.height" mode="indeterminate"></mat-spinner>
            </div>
        }

        <div>
            Selected:    {{ selected() }}
        </div>
    </mat-card-content>
    <mat-card-actions>
        <!-- Container for buttons at the bottom of the card (adds padding) -->
        <button mat-raised-button (click)="save()">Save changes</button>
        <button mat-raised-button (click)="reset()">Reset selection</button>
    </mat-card-actions>
    <mat-card-footer></mat-card-footer>

</mat-card>

<mat-card>
    @if (httpStatus.total > 0) {
        <mat-progress-bar
            [value]="100*httpStatus.fetched/httpStatus.total"
            [bufferValue]="100*httpStatus.requested/httpStatus.total"
            mode="buffer"
        />
    }
    <app-log-list [container]="logs"/>
</mat-card>
