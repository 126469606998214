import {Component, computed, effect} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {MatSelectModule} from "@angular/material/select"
import {Title} from '@angular/platform-browser'
import {toSignal} from "@angular/core/rxjs-interop"

import {PlotlyService, PlotlyViaWindowModule} from 'angular-plotly.js'
import {firstValueFrom, startWith} from 'rxjs'

import {VideDataService} from 'src/app/api/vide-data.service'
import {assertNever, getErrorMessage, getPageTitle, isNotNull} from 'src/app/shared/vide-helper'
import {VideFigure,} from 'src/app/vide-types'
import {INPUT_DATE_MAX, PLOT_CONFIG} from 'src/constants'
import {CorrelationPlotService} from '../correlation-plot/correlation-plot.service'
import {MEASUREMENT_TRANSFORM_KINDS, MeasurementTransformKind} from '../plot-functions'
import {
    DASHES,
    DEFAULT_TIMELINE_MARKERS,
    getSplitter,
    getTimelineLayout,
    getTimelineTracesV2,
    getTitle,
    getUnit,
} from '../timeline-plot/timeline-functions'
import {MeasureType, Statistics, VideObject} from "../../api/api-types"
import {COLOR_SEQUENCES} from "../../shared/colors"

const PLOT_TYPES = ['Timeline', 'Correlation'] as const

@Component({
    selector: 'app-batch-plot',
    templateUrl: './batch-plot.component.html',
    styleUrls: ['./batch-plot.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
})
export class BatchPlotComponent {

    readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    readonly PLOT_TYPES = PLOT_TYPES
    readonly DISPLAY_KINDS = MEASUREMENT_TRANSFORM_KINDS


    readonly batchForm = new FormGroup({
        startDate: new FormControl<string>('', {nonNullable: true}),
        endDate: new FormControl('', {nonNullable: true}),
        width: new FormControl(PLOT_CONFIG.batchPlot.width, {nonNullable: true}),
        height: new FormControl(PLOT_CONFIG.batchPlot.height, {nonNullable: true}),
        prefix: new FormControl('Batchplot_', {nonNullable: true}),
        suffix: new FormControl('', {nonNullable: true}),
        plotType: new FormControl<typeof PLOT_TYPES[number]>('Correlation', {nonNullable: true}),
        measureType: new FormControl<MeasureType | null>(null, {validators: Validators.required, nonNullable: true}),
        displayValue: new FormControl<MeasurementTransformKind>('Resulting value', {nonNullable: true}),

    })
    readonly actionLog = Array<string>()
    readonly failedObjects = Array<VideObject>()
    readonly nonValidatedObjects = Array<VideObject>()

    readonly waiting = toSignal(this.projectDataService.projectWaiting$)
    readonly objects = toSignal(this.projectDataService.selectedObjects$, {initialValue: []})
    private readonly formChanged = toSignal(this.batchForm.valueChanges)

    readonly measureTypes = computed(() => {
        const map = new Map<number, MeasureType>()
        this.objects()
            .flatMap(o => o.statistics.map(s => s.measure_type))
            .forEach(t => {
                map.set(t.id, t)
            })
        return Array.from(map.values())

    })
    readonly nrPlots = computed(() => {
        // dummy to trigger run on form changes
        const dummy = this.formChanged()
        return this.objectsWithData(this.objects()).length
    })

    total = 0
    fetched = 0
    done = 0
    busy = false
    getErrorMessage = getErrorMessage

    constructor(
        private readonly projectDataService: VideDataService,
        private readonly plotlyService: PlotlyService,
        private readonly correlationDataService: CorrelationPlotService,
        private readonly plotDataService: VideDataService,
        private readonly title: Title,
    ) {
        effect(() => {
            const objects = this.objects()
            this.nonValidatedObjects.splice(0)
        })
        this.title.setTitle(getPageTitle(null, 'batch plot'))
        const controls = this.batchForm.controls
        controls.plotType.valueChanges.pipe(startWith(controls.plotType.value)).subscribe(t => {
            const action = t === 'Correlation' ? 'disable' : 'enable'
            controls.measureType[action]()
            controls.displayValue[action]()
        })
    }

    async savePlots(objects: readonly VideObject[]) {
        if (this.busy) {
            console.warn(`Already working`)
            return
        }
        this.batchForm.markAllAsTouched()
        if (this.batchForm.invalid) {
            return
        }
        const objectsToPlot = this.objectsWithData(objects)
        this.failedObjects.splice(0)
        this.nonValidatedObjects.splice(0)
        this.total = 2 * objectsToPlot.length
        this.busy = true
        this.fetched = this.done = 0
        const plotly = await this.plotlyService.getPlotly()
        const options = this.batchForm.getRawValue()
        let fetcher: (o: VideObject) => Promise<{ name: string, data?: VideFigure }>
        switch (options.plotType) {
            case 'Correlation':
                fetcher = this.getCorrelationStuff1
                break
            case 'Timeline':
                const mt = options.measureType
                if (mt === null) {
                    console.error(`Measure type not set, should not be possible`)
                    return
                }
                fetcher = (o: VideObject) => this.getTimelineStuff1(o, mt)
                break
            default:
                assertNever(options.plotType)
        }

        const elem = this.plotlyService.getInstanceByDivId('real')
        const fileOptions = {
            format: 'png',
            width: options.width,
            height: options.height,
            filename: 'bogus',
        }
        for (const o of objectsToPlot) {
            try {
                console.warn(`Processing ${o.name}`)
                const x = await fetcher.call(this, o)
                console.warn(x.name)
                const data = x.data
                if (data === undefined) {
                    console.warn(`No plot for ${x.name}`)
                    this.nonValidatedObjects.push(o)
                    continue
                }
                console.warn(`creating plot for ${x.name}`)
                if (data.layout.xaxis) { // should always be true...
                    data.layout.xaxis.range = [
                        options.startDate,
                        options.endDate,
                    ]
                }
                fileOptions.filename = options.prefix + x.name + options.suffix
                const gd = await plotly.newPlot(elem, data.data, data.layout, data.config)
                const filename = await plotly.downloadImage(gd, fileOptions)
                this.actionLog.push('Created ' + filename)
                console.debug('Created ' + filename)

            } catch (error) {
                console.error(`Some error: `, error)
                this.failedObjects.push(o)
            } finally {
                this.done += 2
            }
        }
        this.busy = false
    }

    private objectsWithData(os: readonly VideObject[]) {
        const options = this.batchForm.getRawValue()
        let predicate: (s: Statistics) => boolean
        switch (options.plotType) {
            case 'Correlation':
                predicate = (s: Statistics) => s.correlation_exists
                break
            case 'Timeline':
                predicate = (s: Statistics) => s.measure_type.id === options.measureType?.id
                break
            default:
                assertNever(options.plotType)
        }
        return os.filter(o => o.statistics.some(predicate))
    }

    private increaseFetched() {
        this.fetched++
        return undefined
    }

    private async getCorrelationStuff1(object: VideObject) {
        const proj = this.projectDataService.project()
        let trace
        if (proj) {
            const collection = await firstValueFrom(this.correlationDataService.getCorrelations(proj, object)).finally(() => {
                this.increaseFetched()
            })
            const c = this.correlationDataService.getBestCorrelation(collection)
            if (c) {
                const [base, ref, correlation] =
                    await firstValueFrom(this.correlationDataService.getCorrelationMeasurements(proj, c)).finally(() => {
                        this.increaseFetched()
                    })
                trace = this.correlationDataService.createCorrelationFigure(base, ref, correlation)
            } else {
                this.increaseFetched()
            }
        } else {
            this.increaseFetched()
        }
        return {name: object.name, data: trace}
    }

    private async getTimelineStuff1(object: VideObject, type: MeasureType) {
        const proj = this.projectDataService.project()
        if (!proj || !object.statistics.some(s => s.measure_type.id === type.id)) {
            return {name: object.name, data: undefined}
        }
        const m = await firstValueFrom(this.plotDataService.getExtendedMeasurements(proj, object, type)).finally(() => {
            this.fetched += 2
        })
        const options = {
            transformKind: 'Resulting value',
            markers: DEFAULT_TIMELINE_MARKERS,
            zeroLevelDate: '',
            zeroLevelTime: '',
            horizontal: []
        } as const
        const split = getSplitter({
            yaxis: 'y',
            yaxisOptions: {transformKind: 'Resulting value', zeroLevelDateTime: '',},
            useMeasureTypeInLabel: false, wrapYears: false, legendMaxLength: 123456
        })(m)
        const yMin = split.valid.flat().map(x => x.value).filter(isNotNull).reduce((acc, curr) => acc < curr ? acc : curr, Infinity)
        const y = getTimelineTracesV2(
            split,
            {
                y: {markers: DEFAULT_TIMELINE_MARKERS},
                y2: {markers: DEFAULT_TIMELINE_MARKERS},
                legendBelow: false,
                wrapYears: false,
            }, {y: yMin, y2: Infinity}, COLOR_SEQUENCES['D3'][0], DASHES[0])
        // const a1 = getTimelinePlotData([m], options)
        const layout = getTimelineLayout({
                title: getTitle(options.transformKind),
                yaxisName: getUnit(options.transformKind, [m]),
                yaxisReversed: false
            }
        )
        const config = await firstValueFrom(this.plotDataService.plotlyConfig$)
        const data: VideFigure = {
            config,
            // data: a1.data,
            data: y,
            layout,
        }
        return {name: object.name, data}
    }

}
