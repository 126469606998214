import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'

import {BehaviorSubject, combineLatest, combineLatestWith, firstValueFrom, map, of, startWith, switchMap} from "rxjs"

import {DiverDatum, DiverService, interpolate, LocalDiverData} from "../diver.service"
import {VideFigure} from "../../../vide-types"
import {MeasurementWithValue, Statistics, VideObject} from "../../../api/api-types"
import {SlimObject} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {dateToDateTimeString, PLOTLY_SCATTER_TYPE} from "../../../shared/vide-helper"
import {measurementNumeric, measurementWithMeasuredValue} from "../../../plot/measurement-categories"
import {DIVER} from "../../../../constants"

/**
 * Add number days to the supplied date, and returns the same instance.
 *
 * @param date
 * @param number
 */
function addDays(date: Date, number: number) {
    const d = date.getDate()
    date.setDate(d + number)
    return date
}

@Injectable({
    providedIn: 'root'
})
export class ReferenceDiverService {
    readonly saving = new BehaviorSubject(false)
    readonly diver = new BehaviorSubject<null | SlimObject>(null)
    readonly localDiverData = new BehaviorSubject<null | LocalDiverData>(null)
    readonly localCompensatedData = new BehaviorSubject<null | {
        baro: readonly DiverDatum[],
        compensated: readonly DiverDatum[],
        diver: readonly DiverDatum[],
    }>(null)

    readonly form = this.fb.nonNullable.group({
        compensatedDiver: [null as null | { object: VideObject, statistics: Statistics }, Validators.required],
        refObject: [null as null | { object: VideObject, statistics: Statistics }, Validators.required],
        refMeasurement: [null as null | MeasurementWithValue, Validators.required],
    })
    private readonly compensatedFileData$ = this.localDiverData.asObservable().pipe(
        map(data => data?.type === 'Compensated' ? data.measurements : null)
    )
    readonly dbCompensated$ = combineLatest([
        this.dataService.projectNotNull$,
        this.diver.asObservable(),
        this.diverService.measureTypeDiver$,
    ]).pipe(
        switchMap(([p, diver, mt]) => {
            if (diver === null) return of(null)
            return this.dataService.getMeasurements(p, diver, mt).pipe(
                map(x => x.measurements
                    .filter(m => m.error_code?.constant_name === 'diver_unreferenced')
                    .filter(measurementWithMeasuredValue)
                    .map(m => {
                        return ({
                            value: m.measured_value,
                            time_t: (new Date(m.measuretime)).getTime(),
                            dateTime: m.measuretime,
                        })
                    })
                ),
            )
        }),
    )
    readonly nrInputData$ = combineLatest([
        this.compensatedFileData$,
        this.localCompensatedData.asObservable(),
        this.dbCompensated$,
    ]).pipe(
        map(([file, local, db]) => {
            return {file: file?.length, local: local?.compensated.length, database: db?.length}
        }),
    )
    readonly combinedCompensatedData$ = combineLatest([
        this.compensatedFileData$,
        this.localCompensatedData.asObservable(),
        this.dbCompensated$,
    ]).pipe(
        map(([diver, local, databas]) => {
            if (!diver && !local && !databas) return null
            const d = diver ? diver.map(x => ({
                dateTime: x.dateTime,
                value: x.value,
                time_t: (new Date(x.dateTime)).getTime(),  //timezone for file data?
            })) : []
            const l = local ? local.compensated : []
            const db = databas ? databas : []
            const compensated = [...d, ...l, ...db]
                .sort((a, b) => a.time_t - b.time_t)
            return {compensated, baro: local?.baro, diver: local?.diver}
        }),
    )
    readonly referenceTimeSpan$ = this.combinedCompensatedData$.pipe(
        map(x => {
            if (!x) return null
            const minX = x.compensated.at(0)?.dateTime
            const maxX = x.compensated.at(-1)?.dateTime
            if (minX && maxX) {
                const min = dateToDateTimeString(addDays(new Date(minX), -DIVER.REFERENCE_OUTSIDE_DATES_DAYS))
                const max = dateToDateTimeString(addDays(new Date(maxX), DIVER.REFERENCE_OUTSIDE_DATES_DAYS))
                return {min, max}
            } else {
                return null
            }
        }),
        // tap(x=>{console.warn(x)}),
    )
    readonly possibleRefObjects$ = combineLatest([
        this.dataService.objects$,
        this.diverService.measureTypeReference$,
        this.referenceTimeSpan$,
    ]).pipe(
        map(([objects, mt, timespan]) =>
            objects.flatMap(o =>
                o.statistics
                    .filter(s => s.measure_type.id === mt.id && s.n_value > 0)
                    .filter(s => {
                        if (!timespan) return true
                        return (!s.last_date_value || s.last_date_value >= timespan.min)
                            && (!s.first_date_value || s.first_date_value <= timespan.max)
                    })
                    .map(s => ({object: o, statistics: s})))
        ),
    )
    /**
     * From the chosen reference object,
     * select all measurements within the time range of the compensated data extended by
     * DIVER_COMPENSATION_REFERENCE_OUTSIDE_DAYS
     */
    readonly possibleRefMeasurements$ = combineLatest([
        this.dataService.projectNotNull$,
        this.diverService.measureTypeReference$,
        this.form.controls.refObject.valueChanges.pipe(startWith(null)),
    ]).pipe(
        switchMap(([p, mt,]) => {
            const refObject = this.form.controls.refObject.value
            if (!refObject) {
                // console.warn('no ref object')
                return of(null)
            }
            return this.dataService.getMeasurements(p, refObject.object, mt)
        }),
        map(x => x ? x.measurements.filter(measurementNumeric) : []),
        combineLatestWith(this.referenceTimeSpan$,),
        map(([m, timespan]) => {
            return m.filter(m => (!timespan) || (m.measuretime >= timespan.min) && (m.measuretime <= timespan.max))
        }),
    )
    readonly referencedData$ = combineLatest([
        this.combinedCompensatedData$,
        this.form.controls.refMeasurement.valueChanges.pipe(startWith(null)),
    ]).pipe(
        map(([data,]) => {
            const refMeasurement = this.form.controls.refMeasurement.value
            // console.log(refMeasurement, data)
            if (!refMeasurement || !data) {
                // console.log('No inputs')
                return null
            }
            const compensated = data.compensated
            if (compensated.length < 1) {
                // console.log('No compensated values')
                return null
            }
            const refTime = (new Date(refMeasurement.measuretime)).getTime()
            // Find the data closest around ref, and interpolate them to refTime.
            // If refTime is outside data, use the first/last data.
            const idx = compensated.findIndex(x => x.time_t > refTime)
            let compareValue
            switch (idx) {
                case 0:
                case -1:
                    // ref is before first data or after last data
                    compareValue = compensated.at(idx)!.value
                    break
                default:
                    // ref is inside data
                {
                    const before = compensated.at(idx - 1)!
                    const after = compensated.at(idx)!
                    compareValue = interpolate({x: before.time_t, y: before.value}, {
                        x: after.time_t,
                        y: after.value
                    }, refTime)
                }
                    break
            }
            const delta = compareValue - refMeasurement.resulting_value
            const referenced = compensated.map(m => {
                return {
                    time: m.time_t,
                    value: m.value - delta,
                    dateTime: m.dateTime,
                }
            })
            return {referenced, compensated, baro: data.baro, diver: data.diver}
        }),
    )
    readonly nrReferenced$ = this.referencedData$.pipe(
        map(d => d?.referenced.length))
    readonly refFigure$ = this.referencedData$.pipe(
        combineLatestWith(this.possibleRefMeasurements$),
        map(([data, ref]) => {
            if (!data) return null
            // if (!data) return EMPTY_FIGURE
            const minX = data.compensated.at(0)?.dateTime
            const maxX = data.compensated.at(-1)?.dateTime
            const traces: VideFigure['data'] = []
            if (data.baro) {
                traces.push({
                    type: PLOTLY_SCATTER_TYPE,
                    x: data.baro.map(m => m.dateTime),
                    y: data.baro.map(m => m.value),
                    mode: 'lines+markers',
                    name: 'Barometer',
                    yaxis: 'y2',
                })
            }
            if (data.diver) {
                traces.push({
                    type: PLOTLY_SCATTER_TYPE,
                    x: data.diver.map(m => m.dateTime),
                    y: data.diver.map(m => m.value),
                    mode: 'lines+markers',
                    name: 'Uncompensated',
                    yaxis: 'y2',
                })
            }
            traces.push({
                type: PLOTLY_SCATTER_TYPE,
                x: data.referenced.map(m => m.dateTime),
                y: data.referenced.map(m => m.value),
                mode: 'lines+markers',
                name: 'Result',
                yaxis: 'y',
            }, {
                type: PLOTLY_SCATTER_TYPE,
                x: data.compensated.map(m => m.dateTime),
                y: data.compensated.map(m => m.value),
                mode: 'lines+markers',
                name: 'Compensated',
                yaxis: 'y2',
            })
            traces.push({
                type: PLOTLY_SCATTER_TYPE,
                x: ref.map(m => m.measuretime),
                y: ref.map(m => m.resulting_value),
                mode: 'markers',
                name: 'Reference measurements',
                yaxis: 'y',
            })
            const figure: VideFigure = {
                data: traces,
                layout: {
                    yaxis2: {
                        title: 'meter H₂O',
                        overlaying: 'y',
                        side: 'right',
                    },

                    yaxis: {
                        title: '+höjd [m]',
                    },
                    xaxis: {range: [minX, maxX]},
                },
                config: {},
            }
            return figure
        })
    )

    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
    }

    async save(object: Pick<VideObject, 'id' | 'name'>) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const measureType = await firstValueFrom(this.diverService.measureTypeDiver$)
        const file = this.localDiverData.value
        const data = (await firstValueFrom(this.referencedData$))?.referenced
        const errorCode = null


        if (!data || data.length < 1) {
            console.error('Nothing to save')
            return
        }
        this.saving.next(true)
        const x = await this.diverService.saveToObject(data, {
            errorCode,
            measureType,
            object,
            update: true,
        }).finally(() => {
            this.saving.next(false)
        })

        if (!x.success) return

        const y = await firstValueFrom(this.dataService.createDiverInstance(project, object, {
            comment: 'Compensated data',
            first_date: data.at(0)!.dateTime,
            serial_number: file?.serial ?? 'unknown',
        }))
        console.log(`Saved installation info for ${object.name}`)
        return y.success
    }
}
