import {AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn} from "@angular/forms"
import {AfterViewInit, Component, effect, forwardRef, input, OnChanges, SimpleChanges, ViewChild} from '@angular/core'
import {JsonPipe, UpperCasePipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatToolbarModule} from "@angular/material/toolbar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {concat, map, Observable} from "rxjs"

import {
    Aquifer,
    CoordinateDeterminationMethod,
    FilterType,
    getTooltip,
    ObjectFile,
    ObjectStatus,
    ObjectType,
    PipeMaterial,
    SettlementPosition,
    TipType,
    VideObject
} from "../api/api-types"
import {ComponentCanDeactivate} from "../can-deactivate.guard"
import {CoordinateTransformService} from "../shared/coordinate-transform.service"
import {EditObjectFragmentService} from "./edit-object-fragment.service"
import {HeaderCasePipe} from "../pipes/header-case.pipe"
import {InputComponent} from "../forms/input/input.component"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {PlotlyComponent, PlotlyViaWindowModule} from "angular-plotly.js"
import {PostResponse} from "../api/api-helper"
import {UNCERTAINTIES_NO_NULL} from "../../constants"
import {VideDataService} from "../api/vide-data.service"
import {getDirtyValues, getErrorMessage} from "../shared/vide-helper"

@Component({
    selector: 'app-edit-object-fragment',
    standalone: true,
    templateUrl: './edit-object-fragment.component.html',
    styleUrls: ['./edit-object-fragment.component.scss'],
    imports: [
        InputComponent,

        HeaderCasePipe,
        JsonPipe,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,
        UpperCasePipe,
        PlotlyViaWindowModule,
        MatSlideToggleModule,
    ],
    providers: [
        {
            provide: ComponentCanDeactivate,
            useExisting: forwardRef(() => EditObjectFragmentComponent)
        },
    ]
})
export class EditObjectFragmentComponent extends ComponentCanDeactivate implements OnChanges, AfterViewInit {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    submitted = false


    object = input.required<VideObject>()
    files = input.required<readonly ObjectFile[]>()

    readonly utility = toSignal(this.dataService.utility$)
    readonly project = this.dataService.project

    readonly coordinateQuality = UNCERTAINTIES_NO_NULL
    readonly optionsNonNullableAttributes = [
        'object_type',
        'object_status',
    ] as const
    readonly wellAttributes = [
        'measurableDepth',
        'filter_length',
        'inclination',
        'direction',
        'well_dimension',
        'bottom_level',
        'reference_level',
        'ground_level',
    ] as const
    protected readonly textAreas = ['comment', 'directions'] as const
    protected readonly optionsAttributes = [
        'aquifer',
        'filter_type',
        'pipe_material',
        'settlement_position',
        'tip_type',
    ] as const

    plotlyStyle = {
        width: '45vw',
        height: '35vh',
    }

    figure = toSignal(this.service.figure$)

    private coordinatesValidator: ValidatorFn = (control: AbstractControl) => {
        // console.log(control)
        if (control instanceof FormGroup) {
            const ew = control.controls['ew']?.value
            const ns = control.controls['ns']?.value
            if (ew !== null && ns !== null || ew === null && ns === null) {
                return null
            }
            return {bothCoordinatesRequired: true}
        }
        return null
    }
    readonly selectFromMap = this.formBuilder.control(false)

    readonly form = this.formBuilder.nonNullable.group({
        alias: null as string | null,
        aquifer: null as Aquifer | null,
        bottom_level: null as number | null,
        comment: null as string | null,
        coordinate_determination_method: null as CoordinateDeterminationMethod | null,
        coordinate_quality: null as number | null,
        correlation_base: false,
        correlation_reference: false,
        direction: null as number | null,
        directions: null as string | null,
        filter_length: null as number | null,
        filter_type: null as FilterType | null,
        ground_level: null as number | null,
        inclination: null as number | null,
        level_quality: null as number | null,
        measurableDepth: null as number | null,
        name: '' as string,
        not_reference_from: null as string | null,
        object_status: null as ObjectStatus | null,
        object_type: null as ObjectType | null,
        original_information: false,
        pipe_material: null as PipeMaterial | null,
        positionM: this.formBuilder.nonNullable.group({
            x: [null as number | null,],
            y: [null as number | null,],
        }),
        reference_level: null as number | null,
        settlement_position: null as SettlementPosition | null,
        source: null as string | null,
        tip_type: null as TipType | null,
        well_dimension: null as number | null,
    }, {validators: this.coordinatesValidator})
    /** Files selected here, to be uploaded to the object */
    readonly newFiles: File[] = []
    /** Existing files that are up for deletion */
    readonly filesToDelete: Array<ObjectFile> = []
    readonly errors: string[] = []

    protected readonly getErrorMessage = getErrorMessage
    private lastMouseCoordinates: { lon: number, lat: number } | undefined

    constructor(
        private readonly dataService: VideDataService,
        private readonly formBuilder: FormBuilder,
        private readonly transform: CoordinateTransformService,
        private readonly service: EditObjectFragmentService,
    ) {
        super()
        effect(() => {
            this.service.object = this.object()
        }, {allowSignalWrites: true})
    }

    ngAfterViewInit(): void {
        this.service.plotlyHost = this

    }

    canDeactivate(): boolean {
        console.warn(this)
        return !this.form.dirty && this.newFiles.length === 0 && this.filesToDelete.length === 0
    }

    save() {
        this.submitted = true
        this.form.markAllAsTouched()
        if (this.form.invalid) {
            console.log('Form invalid')
            return
        }
        const changes = getDirtyValues(this.form)
        console.warn(changes)
        const object = this.object()
        const actions: Array<Observable<{ r: PostResponse<any>, tag: string }>> = []
        const project = this.project()
        if (!project) return
        if (this.form.dirty) {
            const changes = getDirtyValues(this.form)

            // 'null' means the property is deleted in the form.
            // 'undefined' means it is not changed in the form from original value

            // coordinates: set if both x and y are changed...
            let position
            if (changes.positionM) {
                if (changes.positionM.y && changes.positionM.x) {
                    const transform = this.transform.getTransformerToWgs(project.coordinate_system)
                    const startMetric = {x: changes.positionM.x, y: changes.positionM.y}
                    position = transform(startMetric)
                } else {
                    position = null
                }
            }
            if (changes.object_type !== null
                && changes.object_status !== null) {
                actions.push(this.dataService.updateObject(project, {
                    ...changes,
                    position,
                    id: object.id,
                    object_status: changes.object_status,
                    object_type: changes.object_type,
                }).pipe(map(r => {
                    if (r.success) {
                        this.form.markAsPristine()
                    }
                    return {r, tag: object.name}
                })))
            }
        }

        for (const file of this.newFiles) {
            actions.push(this.dataService.createFile(project, object, file).pipe(map(r => {
                if (r.success) {
                    const idx = this.newFiles.indexOf(file)
                    if (idx >= 0) this.newFiles.splice(idx, 1)
                }
                return {r, tag: file.name}
            })))
        }

        for (const file of this.filesToDelete) {
            actions.push(this.dataService.deleteFile(project, file).pipe(map(r => {
                if (r.success) {
                    const idx = this.filesToDelete.indexOf(file)
                    if (idx >= 0) this.filesToDelete.splice(idx, 1)
                }
                return {r, tag: file.name}
            })))
        }


        let success = true
        concat(...actions).subscribe({
            next: value => {
                success &&= value.r.success
                if (!value.r.success) {
                    this.errors.push(`${value.tag}: ${value.r.error}`)
                } else {
                }
            },
            error: err => {
                console.error(err)
            },
            complete: () => {
                if (success) {
                    this.dataService.reloadProjectData()
                    // this.dialogRef.close(true)
                }
            }
        })
    }

    reset() {
        this.form.reset({...this.object(), positionM: this.object().positionM ?? undefined})
        this.newFiles.splice(0)
        this.errors.splice(0)
        this.filesToDelete.splice(0)
        this.service.placedPosition.next(null)
    }

    fileChange(event: Event) {
        if (event.target instanceof HTMLInputElement && event.target.files) {
            // console.warn(event.target.files)
            for (let i = 0; i < event.target.files.length; i++) {
                this.newFiles.push(event.target.files.item(i)!)
            }
        }
    }

    markForDeletion(f: ObjectFile) {
        this.filesToDelete.push(f)
    }

    ngOnChanges(changes: SimpleChanges): void {
        const objectChanges = changes['object']
        if (objectChanges) {
            this.form.patchValue(objectChanges.currentValue)
            this.form.markAsPristine()
        }
    }

    protected readonly getTooltip = getTooltip

    mousedown(event: MouseEvent) {
        this.lastMouseCoordinates = this.getMouseCoordinates(event)
    }

    mouseup(event: MouseEvent) {
        const coord = this.getMouseCoordinates(event)
        if (coord) {
            if (coord.lat === this.lastMouseCoordinates?.lat && coord.lon === this.lastMouseCoordinates?.lon) {
                console.warn("Found down-up click at ", coord)
                this.setCoordinates(coord)
            } else {
                console.warn("Mouse move event")
            }
        }
    }

    private getMouseCoordinates(event: MouseEvent) {
        if (!this.selectFromMap.value) return
        if (!(event.target instanceof HTMLElement)) return
        if (!event.target.classList.contains('mapboxgl-canvas')) return
        // The click was in the correct element, the actual plot
        const plotlyInstance = this.plotlyComponent?.plotlyInstance as any
        if (!plotlyInstance) return
        const subplot = plotlyInstance._fullLayout.mapbox._subplot
        const lon = subplot.xaxis.p2c()
        const lat = subplot.yaxis.p2c()
        if (typeof lat !== 'number' || typeof lon !== 'number') return
        return {lat, lon}
    }

    private setCoordinates(coord: { lat: number, lon: number }) {
        const cds = this.utility()?.coordinate_determination_method.find(s => s.constant_name === 'from_map')
        const coordinateSystem = this.project()?.coordinate_system
        if (!coordinateSystem || !cds) return
        const transform = this.transform.getTransformerFromWgs(coordinateSystem)
        const q = transform(coord)
        this.form.patchValue({positionM: q, coordinate_determination_method: cds})
        this.form.controls.positionM.controls.x.markAsDirty()
        this.form.controls.positionM.controls.y.markAsDirty()
        this.form.controls.coordinate_determination_method.markAsDirty()
        this.service.placedPosition.next({position: coord, positionM: q})
    }

    plotlyRelayout = this.service.plotlyRelayout.bind(this.service)
}

