<mat-card>
    <mat-card-header>
        <mat-card-title> SGU ground water stations</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <p> Selected stations will be added to the project. The object will be shared from the SGU project. New water
            level measurements will be added nightly. </p>
        <p> Data provided from <a href="https://www.sgu.se/">SGU</a> under licence <a
            href="https://creativecommons.org/licenses/by/4.0/legalcode">CC4.0</a>. </p>

        <div class="vide-columns-wide">
            <ng-select
                [items]="SWEDISH_LAN"
                bindLabel="name"
                [formControl]="form.controls.lan"
                [clearable]="false"
            />
            <mat-checkbox [formControl]="form.controls.showInactive">Show inactive stations</mat-checkbox>
        </div>

        <mat-menu #clickMenu>
            <ng-template matMenuContent let-element="element">
                @if (element.tainted) {
                    <button mat-menu-item disableRipple>{{ sguGetObjectName(element) }} in progress</button>
                } @else if (element.external) {
                    <button mat-menu-item (click)="remove(element)">Remove {{ sguGetObjectName(element) }}</button>
                } @else {
                    <button mat-menu-item (click)="add(element)">Add {{ sguGetObjectName(element) }}</button>
                }
            </ng-template>
        </mat-menu>

        <mat-tab-group>
            <mat-tab label="List">
                <table mat-table [dataSource]="stationsToShow$">
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                [matMenuTriggerFor]="clickMenu"
                                [matMenuTriggerData]="{element}"
                                mat-icon-button
                                [disabled]="element.tainted"
                            >
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef>From</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.properties.startdatum_for_matning }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef>To</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.properties.slutdatum_for_matning }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="imported">
                        <th mat-header-cell *matHeaderCellDef>Imported</th>
                        <td mat-cell *matCellDef="let element">
                            @if (element.external) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef>Active</th>
                        <td mat-cell *matCellDef="let element">
                            @if (!element.properties.slutdatum_for_matning) {
                                <mat-icon>check</mat-icon>
                            }
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.properties.stationens_namn }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab [label]="objectWithoutCoordinatesExists()?'Map (not all objects)':'Map'">
                @if (figure(); as figure) {
                    <plotly-plot
                        [data]="figure.data"
                        [layout]="figure.layout"
                        [config]="figure.config"
                        [style]="plotlyStyle"
                        [useResizeHandler]="true"
                        (plotlyClick)="plotlyClick($event)"
                        (relayout)="onPlotlyRelayout($event)"
                    />
                } @else {
                    <div [style.height]="plotlyStyle.height" class="plot-spinner">
                        <mat-spinner mode="indeterminate"></mat-spinner>
                    </div>
                }
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<mat-card>
    @if (inProgress.hasValue()) {
        <mat-progress-bar
            mode="indeterminate"
        />
    }
    <app-log-list [container]="logs"/>
</mat-card>
