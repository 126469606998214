import {Component} from '@angular/core'
import {AsyncPipe, JsonPipe, KeyValuePipe} from "@angular/common"

import {COLOR_SEQUENCES} from "../shared/colors"
import {ApiCallService} from "../api/api-call.service"
import {VideDataService} from "../api/vide-data.service"
import {parseCsv} from "../csv/csv-parser"
import {combineLatest, switchMap} from "rxjs"


@Component({
    selector: 'app-test',
    standalone: true,
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    imports: [
        AsyncPipe,
        JsonPipe,
        KeyValuePipe
    ]
})
export class TestComponent {
    constructor(
        private readonly service: VideDataService,
        private readonly api: ApiCallService,
    ) {
        console.warn(parseCsv(['a,b,c', "\"a\nb\",c,d"].join("\n"), {eol: "\n"}))

        const oid = 18055
        this.service.pid = 4
        combineLatest([
            this.service.projectNotNull$,
            this.service.objects$,
            this.service.utility$,
        ]).pipe(
            switchMap(([p, os, u]) => {
                const o = os.find(o => o.id === oid)
                const mt = u.measure_type.find(t => t.id === 14)
                if (!(o && mt)) throw Error("not found")
                return this.service.getMeasurements(p, o, mt)
            }),
        ).subscribe(x => {
            console.warn(x)
        })
    }

    protected readonly colorSequences = COLOR_SEQUENCES

    getJunk(key: string) {
        const k = key as keyof typeof this.colorSequences
        // return [1,2,3]
        return [...this.colorSequences[k]]
    }

}
