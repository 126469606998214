import {AfterViewInit, Component, OnDestroy, viewChild, ViewChild} from '@angular/core'
import {DatePipe} from "@angular/common"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule, MatMenuTrigger} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatTableModule} from "@angular/material/table"
import {MatTabsModule} from "@angular/material/tabs"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ReactiveFormsModule} from "@angular/forms"
import {toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {PlotlyComponent, PlotlyViaWindowModule} from 'angular-plotly.js'

import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'
import {PlotlyMouseEvent} from 'src/app/vide-types'
import {DATETIME_FORMAT, PLOT_CONFIG} from 'src/constants'
import {getStationNameSmhi, SmhiObject, SmhiService} from "./smhi.service"
import {LogListComponent} from "../../log-list/log-list.component"
import {moveMenu} from "../../shared/mat-menu"


@Component({
    standalone: true,
    selector: 'app-smhi',
    templateUrl: './smhi.component.html',
    imports: [
        LogListComponent,

        DatePipe,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        NgSelectModule,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./smhi.component.scss']
})
export class SmhiComponent extends ComponentCanDeactivate implements AfterViewInit, OnDestroy {
    @ViewChild(PlotlyComponent) plotlyComponent?: PlotlyComponent
    protected readonly DATETIME_FORMAT = DATETIME_FORMAT
    protected readonly figure = toSignal(this.service.figure$)
    protected readonly form = this.service.form
    protected readonly inProgress = this.service.inProgress
    protected readonly logs = this.service.logs
    protected readonly parameters = toSignal(this.service.parameters$)
    protected readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    protected readonly stationsToShow$ = this.service.stationsToShow$
    protected readonly trigger = viewChild(MatMenuTrigger)

    protected readonly displayedColumns = [
        'name',
        'active',
        'from',
        'to',
        'imported',
        'action',
    ] as const

    constructor(
        private service: SmhiService,
    ) {
        super()
    }

    remove = this.service.remove.bind(this.service)
    add = this.service.add.bind(this.service)

    override canDeactivate() {
        return true
    }

    onPlotlyRelayout = this.service.plotlyRelayout.bind(this.service)

    ngAfterViewInit(): void {
        this.service.plotlyHost = this
    }

    ngOnDestroy(): void {
        // not strictly necessary, but let's not keep the unloaded element in memory.
        this.service.plotlyHost = undefined
    }

    plotlyClick(event: PlotlyMouseEvent) {
        const custom = event.points[0]?.customdata as SmhiObject | undefined
        if (typeof custom !== 'object') {
            console.error("Strange type of custom data")
            return
        }
        const trigger = this.trigger()

        if (trigger) {
            trigger.menuData = {element: custom,}
            trigger.openMenu()
        }
        setTimeout(moveMenu(event), 0)
    }


    protected readonly getStationNameSmhi = getStationNameSmhi
}


