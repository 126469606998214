import {Component, effect} from '@angular/core'
import {toSignal} from "@angular/core/rxjs-interop"
import {AsyncPipe, KeyValuePipe} from "@angular/common"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatExpansionModule} from "@angular/material/expansion"
import {MatButtonModule} from "@angular/material/button"
import {RouterLink} from "@angular/router"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {ReactiveFormsModule} from "@angular/forms"
import {MatIconModule} from "@angular/material/icon"

import {NgSelectModule} from "@ng-select/ng-select"
import {firstValueFrom} from "rxjs"
import {PlotlyViaWindowModule} from "angular-plotly.js"
import {PlotHoverEvent, PlotMouseEvent} from "plotly.js"

import {INPUT_DATE_MAX} from 'src/constants'
import {HasFigureData} from "../has-figure-data"
import {TimelinePlotService} from './timeline-plot.service'
import {TIMELINE_COLOR_SEQUENCES} from './timeline-functions'
import {equalIds} from "../../shared/vide-helper"
import {CanSaveData} from "../can-save-data"
import {TimelineTraceOptionsComponent} from "../timeline-trace-options/timeline-trace-options.component"
import {ObjectMapComponent} from "../../object-select/object-map/object-map.component"
import {DatetimeComponent} from "../../forms/datetime/datetime.component"
import {InputComponent} from "../../forms/input/input.component"
import {MeasureType, VideObject} from "../../api/api-types"
import {ObjectHighlightService} from "../../shared/object-highlight.service"
import {HasUncheckedData} from "../has-unchecked-data"

function findFirstMeasureType(objects: VideObject[]): MeasureType | undefined {
    for (const object of objects) {
        for (const statistic of object.statistics) {
            if (statistic.measure_type) {
                return statistic.measure_type
            }
        }
    }
    return undefined
}

@Component({
    selector: 'app-timeline-plot',
    standalone: true,
    templateUrl: './timeline-plot.component.html',
    styleUrls: ['./timeline-plot.component.scss'],
    providers: [
        {provide: HasUncheckedData, useExisting: this},
        {provide: HasFigureData, useExisting: this},
        {provide: CanSaveData, useExisting: this},
    ],
    imports: [
        AsyncPipe,
        DatetimeComponent,
        InputComponent,
        KeyValuePipe,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgSelectModule,
        ObjectMapComponent,
        PlotlyViaWindowModule,
        ReactiveFormsModule,
        RouterLink,
        TimelineTraceOptionsComponent,
    ]
})
export class TimelinePlotComponent implements CanSaveData, HasFigureData, HasUncheckedData {
    readonly INPUT_DATE_MAX = INPUT_DATE_MAX
    readonly TIMELINE_COLOR_SEQUENCES = TIMELINE_COLOR_SEQUENCES
    readonly ACCORDION_STATE = {y: true, y2: false, axes: false}
    readonly form = this.dataService.form

    readonly measureTypes = toSignal(this.dataService.measureTypes$, {initialValue: []})
    readonly plotlyStyle = toSignal(this.dataService.plotlyStyle$)
    readonly usesCorrelation = toSignal(this.dataService.usesCorrelations$)
    readonly horizontal = toSignal(this.dataService.horizontal$, {initialValue: []})
    readonly horizontal2 = toSignal(this.dataService.horizontal2$, {initialValue: []})
    readonly addedObjectsWithData = toSignal(this.dataService.addedObjectsWithData$, {initialValue: []})

    /**
     * By some reason, having a signal does mess up the `figure.layout` object,
     * so we cannot read current values from it later.
     */
    readonly figure$ = this.dataService.figure$

    readonly relayout = this.dataService.relayout.bind(this.dataService)
    readonly afterPlot = this.dataService.afterPlot.bind(this.dataService)
    readonly saveData = this.dataService.saveData.bind(this.dataService)
    readonly getUncheckedData = this.dataService.getUncheckedData.bind(this.dataService)

    readonly equalIds = equalIds

    constructor(
        private readonly dataService: TimelinePlotService,
        private readonly highlight: ObjectHighlightService,
    ) {
        effect(() => {
            // Convenience effect:
            // Make sure nothing invalid is selected for measure-type when selected object/measure-types change
            const addedObjectsWithData = this.addedObjectsWithData()
            const currentTypes = this.measureTypes()
            const patch: Partial<typeof this.form.value> = {}
            const newTypeIds = currentTypes.map(t => t.id)
            const rawValue = this.form.getRawValue()

            // Primary axis
            const selected = rawValue.y.measureTypes
            if (selected.length === 0) {
                // If no measure type is selected, select the first type from the added objects if any.
                // Else the first type from existing types
                const addedType = findFirstMeasureType(addedObjectsWithData)
                if (addedType) {
                    patch.y = {measureTypes: [addedType]}
                } else {
                    patch.y = {measureTypes: currentTypes.slice(0, 1)}
                }
            } else {
                // If a measure type is selected, remove invalid types,
                // and if no type is left, select first from the current types.
                const valid = selected.filter(t => newTypeIds.includes(t.id))
                if (valid.length === 0) {
                    patch.y = {measureTypes: currentTypes.slice(0, 1)}
                } else if (valid.length !== selected.length) {
                    patch.y = {measureTypes: valid}
                }
            }
            // For secondary axis: just remove any types no longer valid
            const selected2 = rawValue.y2.measureTypes
            if (selected2.length > 0) {
                const valid2 = selected2.filter(t => newTypeIds.includes(t.id))
                if (valid2.length !== selected2.length) {
                    patch.y2 = {measureTypes: valid2}
                }
            }
            // Only patch if there is actual change
            if (Object.keys(patch).length > 0) {
                this.form.patchValue(patch)
            }
        })
    }


    clearYRange() {
        this.form.patchValue({axes: {ymin: null, ymax: null,}})
    }

    clearY2Range() {
        this.form.patchValue({axes: {y2min: null, y2max: null,}})
    }

    clearDateRange() {
        this.form.patchValue({axes: {xmin: null, xmax: null}})
    }

    async getFigureData() {
        return await firstValueFrom(this.figure$)
    }

    hover(event: PlotHoverEvent) {
        const point = event.points.at(0)
        const objectId = point?.customdata
        if (typeof objectId !== 'number') {
            console.warn("Unknown custom data for ", point)
            return
        }
        this.highlight.set(objectId)
    }

    unhover(event: PlotMouseEvent) {
        const point = event.points.at(0)
        const objectId = point?.customdata
        if (typeof objectId !== 'number') {
            console.warn("Unknown custom data for ", point)
            return
        }
        this.highlight.unset()
    }
}


