import {Injectable} from '@angular/core'
import {FormBuilder} from '@angular/forms'
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"

import {
    combineLatest,
    combineLatestWith,
    debounceTime,
    filter,
    forkJoin,
    map,
    startWith,
    Subject,
    switchMap,
    tap
} from 'rxjs'

import {VideDataService} from 'src/app/api/vide-data.service'
import {isNotNull} from 'src/app/shared/vide-helper'
import {VideFigure,} from 'src/app/vide-types'
import {FORM_DEBOUNCE_TIME} from 'src/constants'
import {getCorrelationData} from './correlation-functions'
import {getTimelineLayout} from "../timeline-plot/timeline-functions"
import {
    Correlation,
    ExtendedMeasurementResponse,
    MeasurementResponse,
    Project,
    ProjectWithLimit,
    VideObject
} from "../../api/api-types"
import {TimelineTraceOptionsComponent} from "../timeline-trace-options/timeline-trace-options.component"

const NO_VALIDATED_MSG = `No validated correlation exists. Select a
    non-validated correlation manually.`

interface CorrelationCollection {
    readonly validated: Correlation[]
    readonly nonValidated: Correlation[]
    readonly notReference: Correlation[]
}

@Injectable({
    providedIn: 'root',
})
export class CorrelationPlotService {
    readonly problemMessage$ = new Subject<string | null>()

    readonly form = this.formBuilder.nonNullable.group(
        {
            yaxis: TimelineTraceOptionsComponent.getFormGroup(this.formBuilder),
            base_object: null as VideObject | null,
            correlation: null as Correlation | null,
        })

    readonly plotlyStyle$ = this.dataService.plotlyStyle$
    readonly objectsWithCorrelation$ = this.dataService.selectedObjects$.pipe(
        map(objects => objects
            .filter(o => o.statistics.some(s => s.correlation_exists))
            .sort((a, b) => a.name.localeCompare(b.name))
        ),
    )
    readonly figure$ = combineLatest([
        this.form.valueChanges.pipe(
            startWith(null),
            debounceTime(FORM_DEBOUNCE_TIME),
            map(() => this.form.controls.correlation.value),
            filter(isNotNull),
        ),
        this.dataService.projectNotNull$,
    ]).pipe(
        switchMap(([correlation, proj]) => this.getCorrelationMeasurements(proj, correlation)),
        map(([base, ref, correlation]) => {
            const values = this.form.getRawValue()
            const data = getCorrelationData(base, ref, correlation, values.yaxis.markers)
            const layout = getTimelineLayout({
                    title: correlation.measure_type.name,
                    axes: {x: this.dataService.getPlotlyDefaultXRange()},
                    yaxisName: correlation.measure_type.measure_unit.name,
                    yaxisReversed: false, // no reverse y-axis
                }
            )
            return {data, layout}
        }),
        combineLatestWith(this.dataService.plotlyConfig$),
        map(([figure, config]) => {
            const ret: VideFigure = {
                ...figure,
                config
            }
            return ret
        }),
        // tap(x => {            console.warn(x)        }),
    )
    private readonly correlations$ = this.form.controls.base_object.valueChanges.pipe(
        filter(isNotNull),
        combineLatestWith(this.dataService.projectNotNull$),
        switchMap(([o, p]) => this.getCorrelations(p, o)),
        tap(x => this.setBestCorrelation(x)),
        // shareReplay(1),

    )
    readonly groupedCorrelations$ = this.correlations$.pipe(
        map(c => {
            return c
                ? [
                    {
                        title: 'Validated',
                        items: c.validated,
                    },
                    {
                        title: 'Non-validated',
                        items: c.nonValidated,
                    },
                    {
                        title: 'Not reference objects',
                        items: c.notReference
                    }
                ]
                : undefined
        })
    )

    readonly objectSelectionChange$ = (this.dataService.selectionModel.changed)

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dataService: VideDataService,
    ) {
        this.form.controls.correlation.valueChanges.pipe(takeUntilDestroyed()).subscribe(_v => {
            this.problemMessage$.next(null)
        })
    }

    get options() {
        return this.form.getRawValue()
    }

    set options(value: ReturnType<typeof this.form.getRawValue>) {
        this.form.patchValue(value)
    }

    createCorrelationFigure(
        base: ExtendedMeasurementResponse,
        ref: MeasurementResponse,
        correlation: Correlation,
        axes: { x?: any[], y?: any[] } = {}
    ) {
        const values = this.form.getRawValue()
        const data = getCorrelationData(base, ref, correlation, values.yaxis.markers)
        const layout = getTimelineLayout({
            title: correlation.measure_type.name,
            yaxisName: correlation.measure_type.measure_unit.name,
            yaxisReversed: false, // no reverse y-axis
            axes: axes
        })
        const ret: VideFigure = {
            data,
            layout,
            config: {}
        }
        return ret
    }

    /**
     * Get the correlation sorted in order of increasing predIC, grouped in validated and nonValidated
     * @param p
     * @param o
     * @returns
     */
    getCorrelations(p: Project, o: VideObject) {
        return this.dataService.getExtendedObject(p, o).pipe(
            map((base) => {
                const validated = new Array<Correlation>()
                const nonValidated = new Array<Correlation>()
                const notReference = new Array<Correlation>()
                base.correlations.forEach(c => {
                    const refObject = c.ref_object
                    if (refObject.correlation_reference) {
                        if (!refObject.correlation_base || base.validated_correlations.includes(c.id)) {
                            validated.push(c)
                        } else {
                            nonValidated.push(c)
                        }
                    } else {
                        notReference.push(c)
                    }
                })
                const ret: CorrelationCollection = {
                    validated: validated,
                    nonValidated: nonValidated,
                    notReference: notReference,
                }
                return ret
            }),
        )
    }

    getBestCorrelation(cs: CorrelationCollection) {
        // correlation are ordered by pred_ic in the api response.
        return cs.validated.at(0)
    }

    getCorrelationMeasurements(proj: ProjectWithLimit, correlation: Correlation) {
        const baseData = this.dataService.getExtendedMeasurements(
            proj,
            correlation.object,
            correlation.measure_type,
        )
        const refData = this.dataService.getMeasurements(
            proj,
            correlation.ref_object,
            correlation.ref_measure_type,
        )
        return forkJoin([baseData, refData]).pipe(map(x => [...x, correlation] as const))
    }

    private setBestCorrelation(cs: CorrelationCollection) {
        const best = this.getBestCorrelation(cs) ?? null
        console.debug('setting correlation to ', best)
        this.form.controls.correlation.setValue(best)
        // this.correlationControl.setValue(best ?? null)
        if (!best) {
            console.warn("setting warning")
            this.problemMessage$.next(NO_VALIDATED_MSG)
        }
    }
}

