import {Dash} from "plotly.js"

import {COLOR_SEQUENCES} from "src/app/shared/colors"
import {PlotlyData, PlotlyLayout} from "src/app/vide-types"
import {INPUT_DATE_MAX, PLOT_COLOR, PLOT_CONFIG} from "src/constants"
import {
    measurementAffected,
    measurementNormal,
    measurementNotChecked,
    measurementNumeric,
    measurementWithCorrelationStatus
} from "../measurement-categories"
import {errorValueNew, getNewTransform, MeasurementTransformKind} from "../plot-functions"
import {assertNever, isDefined, isNotNull, PLOTLY_SCATTER_TYPE} from "../../shared/vide-helper"
import {
    ExtendedMeasurementResponse,
    Measurement,
    MeasurementResponse,
    MeasureType,
    Trigger,
    VideObject
} from "../../api/api-types"
import {TimelinePlotService} from "./timeline-plot.service"

export const DASHES = [
    "solid",
    "dot",
    "dash",
    "longdash",
    "dashdot",
    "longdashdot",
    // "5px,10px,2px,2px", // does not fit in Plotly type system, and is not actually used...
] as const

export const TIMELINE_COLOR_SEQUENCES = {
    // can we use some kind of filter function to construct this list?
    Alphabet: COLOR_SEQUENCES.Alphabet,
    Dark24: COLOR_SEQUENCES.Dark24,
    Light24: COLOR_SEQUENCES.Light24,
    D3: COLOR_SEQUENCES.D3,
    Plotly: COLOR_SEQUENCES.Plotly,
} as const
export type ColorSequence = keyof typeof TIMELINE_COLOR_SEQUENCES

export function limitedTraceNameNew(
    dataSet: { object: VideObject, measure_type: MeasureType },
    maxLength: number,
    useMeasureType: boolean
) {
    const fullString = [
        dataSet.object.name,
        useMeasureType ? dataSet.measure_type.name : null,
    ].filter(isNotNull).join(' ')
    return fullString.length <= maxLength
        ? fullString
        : fullString.substring(0, maxLength - 1) + '…'
}

const PLOT_FONT_SIZE = 10

export function getTimelineLayout(
    arg: {
        title: string,
        yaxisName: string,
        yaxisReversed: boolean,
        axes?: { x?: any[], y?: any[], y2?: any[] },
        yaxis2Name?: string,
        yaxis2Reversed?: boolean,
        legendBelow?: boolean,
    }
): PlotlyLayout {
    const margin = 40
    // noinspection SpellCheckingInspection
    return {
        autosize: true,
        // width: plotOptions.width ?? undefined,
        // height: plotOptions.height ?? undefined,
        // images: [{
        //     // Bottom left
        //     // x: 0.01,
        //     // y: 0,
        //     // sizex: .1,
        //     // sizey: .1,
        //     // xanchor: "left",
        //     // yanchor: "bottom",
        //     // Top right
        //     x: 1.0,
        //     y: 1.0,
        //     sizex: .5,
        //     sizey: .04, // works well for height=800px
        //     xanchor: "right",
        //     xref: "paper",
        //     yanchor: "bottom",
        //     yref: "paper",
        //     source: "assets/Akvifar-transparent.png",
        // }],
        title: {text: arg.title},
        xaxis: {
            range: arg.axes?.x,
            gridcolor: PLOT_COLOR.grid_color,
            tickfont: {
                // color: 'red',
                size: PLOT_FONT_SIZE
            },

        },
        yaxis: {
            automargin: true,
            title: {
                text: arg.yaxisName,
                standoff: 1,
                font: {size: PLOT_FONT_SIZE}
            },
            tickfont: {size: PLOT_FONT_SIZE,},
            range: arg.axes?.y,
            autorange: arg.yaxisReversed ? 'reversed' : undefined,
            gridcolor: PLOT_COLOR.grid_color,
        },
        yaxis2: {
            title: {
                text: arg.yaxis2Name,
                standoff: 1,
                font: {size: PLOT_FONT_SIZE},
            },
            tickfont: {size: PLOT_FONT_SIZE},
            range: arg.axes?.y2,
            autorange: arg.yaxis2Reversed ? 'reversed' : undefined,
            overlaying: 'y',
            side: 'right',
        },
        margin: {l: margin, r: margin, t: margin, b: margin},
        legend: {
            font: {size: PLOT_FONT_SIZE},
            orientation: arg.legendBelow ? "h" : "v",
            // bordercolor: 'red',
            // borderwidth: 4,
            // Doesn't work
            // entrywidth: 10,
            // entrywidthmode: 'pixels',
            traceorder: 'grouped',
            // title: {side: "top"}
        },
    }
}

export const DEFAULT_TIMELINE_MARKERS = {
    allMeasurements: false,
    nonChecked: true,
    affected: false,
    correlationStatus: false,
    textCode: true,
    notification: true,
}

export function getTimelineExportData(
    measurements: ReadonlyArray<Measurement>,
    object: VideObject,
    measureType: MeasureType,
    options: {
        transformKind: MeasurementTransformKind,
        zeroLevelDateTime: string,
    },
) {
    const newTransform = getNewTransform({
        transformKind: options.transformKind, measureType: measureType, zeroLevelDateTime: options.zeroLevelDateTime
    })(object, measurements)
    return measurements.filter(measurementNormal).map(m => {
        return {
            date: m.measuretime,
            value: m.error_code ? null : newTransform(m),
            kind: options.transformKind,
            status: m.data_status.name,
            code: m.error_code?.name,
            comment: m.comment,
            name: object.name,
            measureType: measureType.name,
            measurement_id: m.id,
            object_id: object.id,
            data_status:m.data_status,
        }
    })
}

export function getTitle(transformKind: MeasurementTransformKind) {
    const noTitleElements: readonly MeasurementTransformKind[] = ['Resulting value', 'Measured value']
    return noTitleElements.includes(transformKind) ? '' : transformKind
}

function getMeasureTypeUnitsString(dataSets: readonly MeasurementResponse[]) {
    const x = dataSets.map(s => s.measure_type.measure_unit.name)
    return Array.from(new Set(x)).join(', ')
}

function getMeasureTypesString(dataSets: readonly MeasurementResponse[]) {
    const x = dataSets.map(s => s.measure_type.name)
    return Array.from(new Set(x)).join(', ')
}


export function getUnit(transformKind: MeasurementTransformKind, dataSets: readonly MeasurementResponse[]) {
    switch (transformKind) {
        case 'Fill rate':
            return '%'
        default:
            return getMeasureTypeUnitsString(dataSets)
    }
}

export function getYaxisName(arg: {
    transformKind: MeasurementTransformKind,
    dataSets: readonly MeasurementResponse[],
    useMeasureType: boolean,
}) {
    const measureTypeString = arg.useMeasureType ? getMeasureTypesString(arg.dataSets) : null

    const noTitleElements = ['Resulting value', 'Measured value']
    const transform = noTitleElements.includes(arg.transformKind) ? null : arg.transformKind
    const unitsString = getMeasureTypeUnitsString(arg.dataSets)
    if (transform === null) {
        if (!measureTypeString) return unitsString
        return `${measureTypeString} [${unitsString}]`
    }
    return transform + (measureTypeString ? `(${measureTypeString})` : '') + `[${unitsString}]`
}


/**
 * Get splitter that splits measurements in chunks of numeric measurements and the rest in one chunk.
 * Invalid data is disregarded altogether.
 *
 * @param yaxisOptions
 * @param wrapYears
 * @param legendMaxLength
 * @param useMeasureTypeInLabel
 * @param yaxis
 */
export function getSplitter(
    {yaxisOptions, wrapYears, legendMaxLength, useMeasureTypeInLabel, yaxis}: {
        yaxisOptions: Pick<ReturnType<TimelinePlotService['form']['controls']['y']['getRawValue']>, 'zeroLevelDateTime' | 'transformKind'>,
        wrapYears: boolean,
        legendMaxLength: number,
        useMeasureTypeInLabel: boolean,
        yaxis: 'y' | 'y2',
    }
) {
    return (r: ExtendedMeasurementResponse & { triggers?: Trigger[] },) => {
        const validMeasurements = r.measurements.filter(measurementNormal)
        const t = getNewTransform({
            transformKind: yaxisOptions.transformKind,
            measureType: r.measure_type,
            zeroLevelDateTime: yaxisOptions.zeroLevelDateTime
        })(r.object, validMeasurements)
        const valid = []
        const invalid: Measurement[] = []
        let current: { value: number | null; measurement: Measurement }[] = []
        validMeasurements.forEach(m => {
            if (!measurementNumeric(m)) {
                valid.push(current)
                current = []
                invalid.push(m)
            } else {
                current.push({value: t(m), measurement: m})
            }
        })
        valid.push(current)

        const traceName = limitedTraceNameNew(r, legendMaxLength, useMeasureTypeInLabel)
        const yearName = validMeasurements.at(0)?.measuretime.substring(0, 4) ?? 'xxx'
        const name = wrapYears ? `${yearName} ${traceName}` : traceName
        return {
            valid: valid.filter(array => array.length > 0),
            invalid,
            name,
            object: r.object,
            measureType: r.measure_type,
            transform: t,
            yaxis,
            triggers: r.triggers
        }
    }
}

type AxisOptions = {
    markers: typeof DEFAULT_TIMELINE_MARKERS,
}

export function getTimelineTracesV2(
    dataSet: {
        valid: { value: number | null; measurement: Measurement }[][];
        transform: (x: Pick<Measurement, "resulting_value" | "measured_value">) => (number | null);
        invalid: Measurement[];
        name: string;
        yaxis: "y" | "y2";
        object: VideObject,
        measureType: MeasureType,
        triggers?: Trigger[],
    },
    options: {
        wrapYears: boolean,
        y: AxisOptions,
        y2: AxisOptions,
        legendBelow: boolean,
    },
    minValues: {
        y: number, y2: number
    },
    color: string,
    dash: Dash,
) {
    const xMapper = options.wrapYears
        ? (x: Measurement) => `1970-${x.measuretime.substring(5)}`
        : (x: Measurement) => x.measuretime
    const legendgroup = `${dataSet.name}-${dataSet.measureType.id}` //  idx.toString()
    const legendrank = 1000
    const validMode: PlotlyData['mode'] = options[dataSet.yaxis].markers.allMeasurements ? 'lines+markers' as const : 'lines' as const
    let makeLegend = true
    const traces = dataSet.valid.map((validData) => {
        const x = validData.map(x => xMapper(x.measurement))
        const y = validData.map(m => m.value)
        const text = validData.map(m => m.measurement.comment ?? '')
        const ret: PlotlyData = {
            customdata: validData.map(() => dataSet.object.id),
            x,
            y,
            yaxis: dataSet.yaxis,
            text,
            type: PLOTLY_SCATTER_TYPE,
            name: dataSet.name,
            line: {color, dash},
            hoverinfo: 'x+y+text',
            mode: validData.length === 1 ? 'markers' as const : validMode,
            legendgroup,
            legendrank,
            showlegend: makeLegend,
            vide: {
                object: dataSet.object,
                measureType: dataSet.measureType,
            }
        }
        makeLegend = false
        return ret
    })

    if (!makeLegend && traces.length > 1) {
        // There is a legend trace, make sure it has length > 1 if possible
        const idx = traces.findIndex(t => t.x && t.x.length > 1)
        if (idx !== 0 && idx !== -1) {
            // Some trace other than the first has a line, use it for the legend
            traces[0]!['showlegend'] = false as const
            traces[idx]!['showlegend'] = true as const
        }
    }

    if (!options.wrapYears) {
        // Sort traces if not year-wrapping
        const compareFn = options.legendBelow
            // by name
            ? (a: PlotlyData, b: PlotlyData) => {
                const aa = a.name
                if (!aa) return 1
                const bb = b.name
                if (!bb) return -1
                return aa.localeCompare(bb)
            }
            // by last value
            : (a: PlotlyData, b: PlotlyData) => {
                const aa = a.y?.at(-1)
                if (typeof aa !== 'number') return 1
                const bb = b.y?.at(-1)
                if (typeof bb !== 'number') return -1
                return bb - aa
            }
        traces.sort(compareFn)
    }

    if (options[dataSet.yaxis].markers.textCode && dataSet.invalid.length > 0) {
        // one trace for all text codes (error codes)
        const failSequence = dataSet.invalid
        const trace: PlotlyData = {
            customdata: failSequence.map(() => dataSet.object.id),
            textfont: {color},
            mode: 'text' as const,
            text: failSequence.map(m => m.error_code?.symbol ?? 'x'),
            x: failSequence.map(m => {
                return {measurement: m}
            }).map(x => xMapper(x.measurement)),
            y: failSequence.map(m => errorValueNew(m, dataSet.object, minValues[dataSet.yaxis], dataSet.transform)),
            yaxis: dataSet.yaxis,
            name: dataSet.name,
            hovertext: failSequence.map(m => {
                const possibleTexts = [
                    // all data will have error_code, but the type doesn't know...
                    m.error_code?.name,
                    m.data_status.constant_name !== 'data_status_standard' ? m.data_status.name : null,
                    m.comment]
                return possibleTexts.filter(isDefined).join(', ')
            }),
            hoverinfo: 'x+text' as const,
            // hovertemplate: failSequence.map(m => `%{x}<br>${m.error_code?.name}<extra>${m.comment}</extra>`),
            showlegend: false, // TODO: must make sure there is exactly one legend for this object/measure-type combo!
            legendgroup,
        }
        traces.push(trace)
    }

    if (options[dataSet.yaxis].markers.nonChecked) {
        // trace for data status 'not checked'
        const toMark = dataSet.valid
            .flat()
            .map(x => x.measurement)
            .filter(measurementNotChecked)
        if (toMark.length > 0) {
            const text = toMark.map(m => m.data_status.name)
            const trace: PlotlyData = {
                customdata: toMark.map(() => dataSet.object.id),
                x: toMark.map(xMapper),
                y: toMark.map(dataSet.transform),
                yaxis: dataSet.yaxis,
                text,
                hoverinfo: 'text',
                marker: {
                    ...PLOT_CONFIG.timeline.markerNonChecked,
                    color,
                },
                mode: 'markers',
                showlegend: false,
                legendgroup: legendgroup,
            }
            traces.push(trace)
        }
    }
    if (options[dataSet.yaxis].markers.affected) {
        // trace for data status affected
        const toMark = dataSet.valid
            .flat()
            .map(x => x.measurement)
            .filter(measurementAffected(dataSet.object))
        if (toMark.length > 0) {
            const remark = `affected from ${dataSet.object.not_reference_from}`
            const text = toMark.map(m => [
                m.data_status.name,
                m.measuretime >= (dataSet.object.not_reference_from ?? INPUT_DATE_MAX) ? remark : null
            ].filter(isDefined).join(', '))
            const trace: PlotlyData = {
                customdata: toMark.map(() => dataSet.object.id),
                x: toMark.map(xMapper),
                y: toMark.map(dataSet.transform), // TODO: use dataSet.value?!
                yaxis: dataSet.yaxis,
                text,
                hoverinfo: 'text',
                marker: {
                    ...PLOT_CONFIG.timeline.markerAffected,
                    color,
                },
                mode: 'markers',
                showlegend: false,
                legendgroup: legendgroup,
            }
            traces.push(trace)
        }
    }
    if (options[dataSet.yaxis].markers.notification) {
        // trace for trigger active
        const toMark = dataSet.triggers?.map(t => {
            let tr: (m: Measurement) => boolean
            switch (t.type) {
                case "min":
                    tr = (m: Measurement) => m.resulting_value !== null && m.resulting_value < t.limit
                    break
                case "max":
                    tr = (m: Measurement) => m.resulting_value !== null && m.resulting_value > t.limit
                    break
                default:
                    assertNever(t.type)
            }
            return dataSet.valid.flat()
                .filter(x => tr(x.measurement))
                .map(x => ({...x, text: `Condition ${t.type} ${t.limit}`}))
        }).flat()


        if (toMark && toMark.length > 0) {
            const trace: PlotlyData = {
                customdata: toMark.map(() => dataSet.object.id),
                x: toMark.map(x => xMapper(x.measurement)),
                y: toMark.map(x => x.value),
                yaxis: dataSet.yaxis,
                text: toMark.map(x => x.text),
                hoverinfo: 'text',
                marker: {
                    ...PLOT_CONFIG.timeline.markerTriggered,
                },
                mode: 'markers',
                showlegend: false,
                legendgroup: legendgroup,
            }
            traces.push(trace)
        }
    }

    if (options[dataSet.yaxis].markers.correlationStatus) {
        // trace for correlation status !== null
        const toMark = dataSet.valid
            .flat()
            .map(x => x.measurement)
            .filter(measurementWithCorrelationStatus)
        if (toMark.length > 0) {
            const statusColor = toMark.map(m => {
                switch (m.data_correlation_status.constant_name) {
                    case 'not_affected':
                        return color
                    case 'affected_down':
                    case 'affected_up':
                        return PLOT_CONFIG.timeline.colorAffected
                    case 'no_result':
                        return PLOT_CONFIG.timeline.colorNoResult
                    default:
                        assertNever(m.data_correlation_status.constant_name)
                }
            })
            const trace: PlotlyData = {
                customdata: toMark.map(() => dataSet.object.id),
                x: toMark.map(xMapper),
                y: toMark.map(m => dataSet.transform(m)),
                yaxis: dataSet.yaxis,
                text: toMark.map(m => m.data_correlation_status.name),
                hoverinfo: 'text',
                marker: {
                    ...PLOT_CONFIG.timeline.markerCorrelationStatus,
                    color: statusColor,
                },
                mode: 'markers',
                showlegend: false,
                legendgroup,
            }
            traces.push(trace)
        }
    }

    return traces
}
