import {FormBuilder, Validators} from "@angular/forms"
import {Injectable} from '@angular/core'

import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    map,
    Observable,
    of,
    shareReplay,
    startWith,
    switchMap,
    tap
} from "rxjs"

import {DiverDatum, DiverService, interpolate, LocalDiverData} from "../diver.service"
import {VideFigure} from "../../../vide-types"
import {SlimObject} from "../diver.component"
import {Statistics, VideObject} from "../../../api/api-types"
import {VideDataService} from "../../../api/vide-data.service"
import {PLOTLY_SCATTER_TYPE} from "../../../shared/vide-helper"
import {measurementNumeric, measurementWithMeasuredValue} from "../../../plot/measurement-categories"

@Injectable({
    providedIn: 'root'
})
export class CompensateDiverService {
    readonly saving = new BehaviorSubject(false)
    readonly baroForm = this.fb.nonNullable.control(null as null | {
        object: VideObject,
        statistics: Statistics
    }, Validators.required)
    readonly diverObject = new BehaviorSubject<null | SlimObject>(null)
    readonly fileData = new BehaviorSubject<null | LocalDiverData>(null)
    private readonly uncompensatedFileData$ = this.fileData.asObservable().pipe(
        map(data => data?.type === 'Uncompensated' ? data.measurements : null)
    )

    readonly dbUncompensated$ = combineLatest([
        this.dataService.projectNotNull$,
        this.diverObject.asObservable(),
        this.diverService.measureTypeDiver$,
    ]).pipe(
        switchMap(([p, diver, mt]) => {
            if (diver === null) return of(null)
            return this.dataService.getMeasurements(p, diver, mt).pipe(
                map(x => x.measurements
                    .filter(m => m.error_code?.constant_name === 'diver_uncompensated')
                    .filter(measurementWithMeasuredValue)
                    .map(m => {
                        return ({
                            value: m.measured_value,
                            time_t: (new Date(m.measuretime)).getTime(),
                            dateTime: m.measuretime,
                        })
                    })
                ),
                // map(x => ({measurements: x, })),
            )
        }),
        // tap(x => {            console.warn(x?.at(0), x?.at(-1))        }),
    )
    readonly nrInputData$ = combineLatest([
        this.uncompensatedFileData$,
        this.dbUncompensated$,
    ]).pipe(
        map(([file, db]) => {
            return {file: file?.length, database: db?.length}
        }),
    )
    private readonly commonUncompensatedData$: Observable<null | readonly DiverDatum[]> = combineLatest([
        this.uncompensatedFileData$,
        this.dbUncompensated$,
    ]).pipe(
        map(([local, db]) => {
            if (!local && !db) return null

            const l = local ? local.map(x => ({
                dateTime: x.dateTime,
                value: x.value,
                time_t: (new Date(x.dateTime)).getTime(),
            })) : []
            const d = db || []
            return [...l, ...d].sort((a, b) => a.time_t - b.time_t)
        }),
        // tap(x => {            console.warn(x?.at(0), x?.at(-1))        }),
    )
    readonly baroCombos$ = this.dataService.objects$.pipe(
        map(objects => {
            return objects.map(o => o.statistics
                .filter(s => {
                    return s.measure_type.constant_name === 'air_pressure' || s.measure_type.constant_name === 'air_pressure_sea_level'
                })
                .map(s => ({object: o, statistics: s})))
                .flat()
        }),
    )
    private readonly baroData$: Observable<null | readonly DiverDatum[]> = combineLatest(([
        this.dataService.projectNotNull$,
        this.baroForm.valueChanges.pipe(startWith(null)),
    ])).pipe(
        switchMap(([p, baro,]) => {
            if (baro === null) {
                return of(null)
            }
            return this.dataService.getMeasurements(p, baro.object, baro.statistics.measure_type).pipe(
                map(x => {
                    let factor: number
                    switch (x.measure_type.measure_unit.constant_name) {
                        case 'mH2O':
                            factor = 1
                            break
                        case 'hecto_pascal':
                            const g = 9.818
                            const rho = 999.975 // at 4°C
                            factor = 100 / (g * rho)
                            break
                        default:
                            throw new Error("Wrong measure type " + x.measure_type.measure_unit.name)
                    }
                    return x.measurements.filter(measurementNumeric).map(m => ({
                        value: factor * m.resulting_value,
                        time_t: (new Date(m.measuretime)).getTime(),
                        dateTime: m.measuretime,
                    }))
                }),)
        }),
    )

    readonly localCompensatedData$ = combineLatest(([
        this.baroData$,
        this.commonUncompensatedData$,
    ])).pipe(
        map(([baro, diver]) => {
            if (baro === null || diver === null) {
                // console.log("no input", baro, diver)
                return null
            }
            const compensated = new Array<DiverDatum>()
            const skipped = new Array<DiverDatum>()
            const baroLength = baro.length
            let baroIdx = 0
            let b
            for (const d of diver) {
                // increase baro index until baro time is >= diver time
                while (baroIdx < baroLength && (b = baro.at(baroIdx)) && b.time_t < d.time_t) {
                    baroIdx++
                }
                if (!b) {
                    // console.error('got undefined baro value , quitting')
                    break
                }
                if (baroIdx === baroLength) {
                    // console.error("Baro drop off at end, quitting")
                    break
                }
                let diff
                if (b.time_t === d.time_t) {
                    // console.info("exact time match")
                    diff = (d.value - b.value)
                } else if (baroIdx === 0) {
                    // console.warn("First baro is after first diver, skipping this diver value")
                    skipped.push(d)
                    continue
                } else {
                    // TODO: test if baro it too far from diver time, and then skip
                    // if ( baroTooFarAway ){
                    //     skipped.push(d)
                    //     continue
                    // }
                    const bBefore = baro.at(baroIdx - 1)! // we know baroIdx > 0 from above, so no we know index is valid.
                    const i = interpolate({x: bBefore.time_t, y: bBefore.value}, {x: b.time_t, y: b.value}, d.time_t)
                    diff = d.value - i
                }
                compensated.push({
                    value: diff,
                    time_t: d.time_t,
                    dateTime: d.dateTime,
                })
            }
            return {compensated, skipped, baro, diver}
        }),
        shareReplay({refCount: true, bufferSize: 1}),
        // tap(x => {            console.warn(x?.compensated.at(0), x?.compensated.at(-1))        }),
    )
    readonly compensatedNrData$ = this.localCompensatedData$.pipe(
        map(data => data?.compensated.length)
    )
    readonly compensatedFigure$ = this.localCompensatedData$.pipe(
        map(data => {
            if (!data) return null
            // if (!data) return EMPTY_FIGURE
            const minX = data.diver.at(0)?.dateTime
            const maxX = data.diver.at(-1)?.dateTime
            const figure: VideFigure = {
                data: [
                    {
                        type: PLOTLY_SCATTER_TYPE,
                        x: data.diver.map(m => m.dateTime),
                        y: data.diver.map(m => m.value),
                        mode: 'lines+markers',
                        name: 'Uncompensated',
                    },
                    {
                        type: PLOTLY_SCATTER_TYPE,
                        x: data.baro.map(m => m.dateTime),
                        y: data.baro.map(m => m.value),
                        mode: 'lines+markers',
                        name: 'Barometer',
                    },
                    {
                        type: PLOTLY_SCATTER_TYPE,
                        x: data.compensated.map(m => m.dateTime),
                        y: data.compensated.map(m => m.value),
                        mode: 'lines+markers',
                        name: 'Compensated',
                    },
                ],
                layout: {
                    showlegend: true,
                    xaxis:{range:[minX,maxX]},
                },
                config: {},
            }
            return figure
        }),
    )


    constructor(
        private readonly fb: FormBuilder,
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
    ) {
    }

    async save(object: Pick<VideObject, 'id' | 'name'>) {
        const p = this.dataService.project()
        const file = await firstValueFrom(this.fileData)
        const localData = await firstValueFrom(this.localCompensatedData$)
        const measureType = await firstValueFrom(this.diverService.measureTypeDiver$)
        const errorCodeUncompensated = await firstValueFrom(this.dataService.errorCodeUncompensated$)
        const errorCodeUnreferenced = await firstValueFrom(this.dataService.errorCodeUnreferenced$)

        if (!localData || !p || !measureType || !file) {
            throw new Error('Missing input values')
        }
        if (localData.compensated.length < 1) {
            console.error('No values')
            return
        }
        const x = await this.diverService.saveToObject(localData.compensated, {
            errorCode: errorCodeUnreferenced,
            measureType,
            object,
            update: true,
        })
        if (localData.skipped.length > 0) {
            const x2 = await this.diverService.saveToObject(localData.skipped, {
                errorCode: errorCodeUncompensated,
                measureType,
                object,
                update: true,
            })
        }
        this.saving.next(true)
        const y = await firstValueFrom(this.dataService.createDiverInstance(p, object, {
            comment: 'Compensated data',
            first_date: localData.compensated.at(0)!.dateTime,
            serial_number: file.serial,
        })).finally(() => {
            this.saving.next(false)
        })
        console.log(`Saved installation info for ${object.name}`)
        return y.success
    }
}
