import {Component} from '@angular/core'
import {toSignal} from "@angular/core/rxjs-interop"
import {AsyncPipe, JsonPipe} from "@angular/common"
import {MatProgressSpinner} from "@angular/material/progress-spinner"
import {MatButtonModule} from "@angular/material/button"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatCardModule} from "@angular/material/card"

import {map} from "rxjs"
import {PlotlyViaWindowModule} from "angular-plotly.js"

import {PLOT_CONFIG} from "../../../constants"
import {PlotlyMouseEvent, PlotlySelectionEvent} from "../../vide-types"
import {GeoarkivetService} from "./geoarkivet.service"
import {ComponentCanDeactivate} from "../../can-deactivate.guard"
import {LogListComponent} from "../../log-list/log-list.component"

@Component({
    selector: 'app-geoarkivet',
    standalone: true,
    imports: [
        AsyncPipe,
        JsonPipe,
        LogListComponent,
        MatButtonModule,
        MatCardModule,
        MatProgressBarModule,
        MatProgressSpinner,
        PlotlyViaWindowModule,
    ],
    templateUrl: './geoarkivet.component.html',
    styleUrl: './geoarkivet.component.scss'
})
export class GeoarkivetComponent extends ComponentCanDeactivate {
    readonly plotlyStyle = PLOT_CONFIG.plotlyStyle
    readonly logs = this.dataService.logs
    readonly httpStatus = this.dataService.httpStatus

    readonly figure = toSignal(this.dataService.figure$)
    // readonly external = toSignal(this.dataService.externalSelection$)
    readonly selected = toSignal(this.dataService.selected$.pipe(
        map(selected => {
            return selected.map(s => s.properties.Name).join(', ')
        })
    ), {initialValue: ''})

    onPlotlyRelayout = this.dataService.plotlyRelayout.bind(this.dataService)
    save = this.dataService.save.bind(this.dataService)
    reset = this.dataService.reset.bind(this.dataService)

    constructor(
        private readonly dataService: GeoarkivetService,
    ) {
        super()
    }

    override canDeactivate(): boolean {
        return true
    }

    plotlySelected(event: PlotlySelectionEvent) {
        const ids = event.points.map(p => p.id)
        console.log(ids)
        this.dataService.select(ids)

        // Don't use the shift/clear selection stuff
        // Use the global Window::Event as plotly does not pass the full event,
        // but just the selection details.
        // const mEvent = window.event as MouseEvent
        // if (!mEvent?.shiftKey) {
        //   this.selectionModel.clear()
        // }
    }

    plotlyClick(event: PlotlyMouseEvent) {
        // console.log(event)
        const id = event.points[0]?.id
        console.log(id)
        if (id) {
            this.dataService.toggle(id)
        }
    }

}
