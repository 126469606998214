<div class="padded">
    <h1> Public data</h1>
    <p>
        Several sources of public data is available. They fetch measurements from public sources, and updates them every
        night.
    </p>

    <h2>SMHI</h2>
    <ul>
        <li>
            Select
            <app-menu-selection [steps]="['Import','SMHI']"/>
            .
        </li>
        <li>
            Select one of the measure types available.
        </li>
        <li>
            Optionally check 'Show inactive stations' to include the stations that are marked as inactive by SMHI.
        </li>
        <li>
            Select one or more stations by clicking in the map. The selected stations are colored red. Click again to
            deselect.
        </li>
        <li>
            Select 'Save changes'
        </li>
        <li>
            The objects are now available in the project. The measurements may take some time to be fetched, so if they
            are
            not available try reloading after some minutes.
        </li>
    </ul>
</div>
