import * as S from "superstruct"

import {PROJECT_ABILITIES} from "../ability"
import {Interval} from "./limit-storage"

const NamedId = S.object({
    id: S.integer(),
    name: S.string()
})

export const MeasureUnit = S.assign(NamedId, S.object({
    constant_name: S.enums([
        'hecto_pascal',
        'millimeter',
        'liter_per_minute',
        'plushojd',
        'degree_celcius',
        'meter',
        'liter_per_second',
        'bar',
        'mH2O',
    ])
}))
export type MeasureUnit = S.Infer<typeof MeasureUnit>
export const MeasureType = S.assign(NamedId, S.object({
    calculation_type: S.enums(['compensation', 'measurement', 'fathom']),
    constant_name: S.nullable(S.enums([
        'measure_type_level',
        'measure_type_settlement',
        'air_pressure',
        'air_pressure_sea_level',
        'measure_type_diver',
    ])),
    measure_unit: MeasureUnit,
    name_smhi: S.nullable(S.string()),
}))
export type MeasureType = S.Infer<typeof MeasureType>

const RawAberration = S.object({
    correlation_id: S.integer(),
    time_ekv: S.integer(),
    difference_ovpv: S.number(),
    p: S.min(S.max(S.number(), 1), 0),
    outside_ref_limit: S.boolean(),
    aberration: S.boolean(),
})
export const RawCorrelation = S.object({
    aberrations: S.array(RawAberration),
    first_init_at: S.string(),
    id: S.integer(),
    intercept: S.number(),
    last_init_at: S.string(),
    measure_type_id: S.integer(),
    object_id: S.integer(),
    pred_ic: S.number(),
    r2: S.number(),
    ref_lim_max: S.number(),
    ref_lim_min: S.number(),
    ref_measure_type_id: S.integer(),
    ref_object_id: S.integer(),
    x_coeff: S.number(),
})
export type Correlation = Omit<S.Infer<typeof RawCorrelation>,
    | 'measure_type_id'
    | 'object_id'
    | 'ref_measure_type_id'
    | 'ref_object_id'
> & {
    readonly measure_type: MeasureType
    readonly object: VideObject
    readonly ref_measure_type: MeasureType
    readonly ref_object: VideObject
}
export const LentObject = S.object({
    id: S.integer(),
    name: S.string(),
    shared_to: S.array(S.object({
        project_id: S.integer(),
        project_name: S.string(),
        readonly: S.boolean(),
        comment: S.nullable(S.string()),
        license_name: S.string(),
    }))
})
export type LentObject = S.Infer<typeof LentObject>
export const RawObject = S.object({
    alias: S.nullable(S.string()),
    aquifer_id: S.nullable(S.integer()),
    bottom_level: S.nullable(S.number()),
    comment: S.nullable(S.string()),
    coordinate_determination_method_id: S.nullable(S.integer()),
    coordinate_quality: S.nullable(S.number()),
    correlation_base: S.boolean(),
    correlation_reference: S.boolean(),
    direction: S.nullable(S.number()),
    directions: S.nullable(S.string()),
    filter_length: S.nullable(S.number()),
    filter_type_id: S.nullable(S.integer()),
    ground_level: S.nullable(S.number()),
    id: S.integer(),
    inclination: S.nullable(S.number()),
    last_changed_by: S.nullable(S.string()),
    latitude: S.nullable(S.number()),
    level_quality: S.nullable(S.number()),
    longitude: S.nullable(S.number()),
    measurableDepth: S.nullable(S.number()),
    name: S.string(),
    not_reference_from: S.nullable(S.string()),
    object_status_id: S.integer(),
    object_type_id: S.integer(),
    original_information: S.boolean(),
    owner: S.optional(S.object({
        project_name: S.string(),
        project_owner: S.string(),
        height_system: S.string(),
    })),
    pipe_material_id: S.nullable(S.integer()),
    project_id: S.number(),
    readonly: S.optional(S.boolean()),
    reference_level: S.nullable(S.number()),
    settlement_position_id: S.nullable(S.integer()),
    source: S.nullable(S.string()),
    time_changed: S.string(),
    time_created: S.string(),
    tip_type_id: S.nullable(S.integer()),
    well_dimension: S.nullable(S.number()),
})
export type VideObjectBasicType = Omit<S.Infer<typeof RawObject>,
    | 'aquifer_id'
    | 'coordinate_determination_method_id'
    | 'coordinate_system_id'
    | 'filter_type_id'
    | 'latitude'
    | 'longitude'
    | 'object_status_id'
    | 'object_type_id'
    | 'pipe_material_id'
    | 'settlement_position_id'
    | 'tip_type_id'
>

export type Position = {
    readonly lat: number
    readonly lon: number
};
export type PositionM = {
    readonly x: number
    readonly y: number
};
export type VideObject = VideObjectBasicType & {
    aquifer: Aquifer | null,
    coordinate_determination_method: CoordinateDeterminationMethod | null,
    filter_type: FilterType | null,
    object_status: ObjectStatus,
    object_type: ObjectType,
    pipe_material: PipeMaterial | null,
    position: Position | null,
    positionM: { x: number, y: number } | null,
    settlement_position: SettlementPosition | null,
    statistics: readonly Statistics[],
    tip_type: TipType | null,
}
const VIDE_OBJECT_ATTRIBUTE_DESCRIPTION: { [Property in keyof VideObject | 'ew' | 'ns']?: string } = {
    alias: "Alternative name for the object",
    coordinate_quality: "Accuracy for coordinates",
    direction: "Direction of bore hole. 0 = north. 90 = east.",
    directions: "Description of where the object is situated",
    inclination: "Degrees from vertical. 0 = vertical. 90 = horizontal.",
    level_quality: "Accuracy for ground level and other levels",
    original_information: "Objects marked as Original information may not be deleted and measurements may not be replaced on import.",
    owner: "Project [Licence] if shared from another project",
    source: "Where the object was imported from into the database. Cannot be edited.",
}

export function getTooltip(def: keyof typeof VIDE_OBJECT_ATTRIBUTE_DESCRIPTION) {
    return VIDE_OBJECT_ATTRIBUTE_DESCRIPTION[def] ?? ''
}

const DeviationStatus = S.object({
    comment: S.string(),
    constant_name: S.nullable(S.enums(['deviation_status_confirmed', 'deviation_status_new', 'deviation_status_standard'])),
    id: S.integer(),
    name: S.string(),
    standard: S.boolean(),
})
export type DeviationStatus = S.Infer<typeof DeviationStatus>
export const RawStatistics = S.object({
    avg_year_max: S.nullable(S.number()),
    avg_year_min: S.nullable(S.number()),
    correlation_as_reference_exists: S.boolean(),
    correlation_exists: S.boolean(),
    first_date: S.nullable(S.string()),
    first_date_value: S.nullable(S.string()),
    id: S.number(),
    last_date: S.nullable(S.string()),
    last_date_value: S.nullable(S.string()),
    last_value: S.nullable(S.number()),
    max: S.nullable(S.number()),
    mean: S.nullable(S.number()),
    measure_type_id: S.integer(),
    min: S.nullable(S.number()),
    n: S.number(),
    n_value: S.number(),
    non_checked_measurements_exists: S.boolean(),
    object_id: S.number(),
    time_created: S.string(),
})
export type Statistics = Omit<S.Infer<typeof RawStatistics>, 'measure_type_id'> & {
    measure_type: MeasureType
}
export const DataDeviation = S.object({
    aberration_rate: S.number(),
    aberration_repetitions: S.number(),
    deviation_status: DeviationStatus,
    outside_ref_limits_count: S.number(),
    rank1_id_corr: S.nullable(S.number()),
    sum_of_p: S.number(),
    time_ekv: S.number(),
})
export type DataDeviation = S.Infer<typeof DataDeviation>
const ExtendedStatistics = S.omit(S.assign(RawStatistics, S.object({
    data_deviations: S.array(DataDeviation),
    measure_type: MeasureType,
})), [])
export const RawExtendedObject = S.assign(RawObject, S.object({
    correlations: S.array(RawCorrelation),
    statistics: S.array(ExtendedStatistics),
    validated_correlations: S.array(S.integer())
}))
export const RawPlotOptions = S.object({
    id: S.integer(),
    name: S.string(),
    options: S.string(),
})
export const DiverInstance = S.object({
    comment: S.nullable(S.string()),
    first_date: S.string(),
    id: S.integer(),
    object_id: S.integer(),
    reference_measurement_id: S.nullable(S.integer()),
    serial_number: S.string(),
})
export type DiverInstance = S.Infer<typeof DiverInstance>
export const RawMeasurements = S.object({
    comment: S.nullable(S.string()),
    data_correlation_status_id: S.nullable(S.integer()),
    data_status_id: (S.integer()),
    error_code_id: S.nullable(S.integer()),
    id: S.number(),
    last_changed_by: S.nullable(S.string()),
    measure_type_id: (S.integer()),
    measured_value: S.nullable(S.number()),
    measuretime: S.string(),
    object_id: S.number(),
    // operator_id: S.nullable(S.integer()),
    resulting_value: S.nullable(S.number()),
    source: S.nullable(S.string()),
    time_changed: S.string(),
    time_created: S.string(),
    diver_installation_id: S.nullable(S.integer()),
})
export type Measurement = Omit<S.Infer<typeof RawMeasurements>,
    | 'data_correlation_status_id'
    | 'data_status_id'
    | 'error_code_id'
    | 'measure_type_id'
    | 'object_id'
> & {
    // properties to fill for the data service
    readonly data_correlation_status: DataCorrelationStatus | null
    readonly data_status: DataStatus
    readonly error_code: ErrorCode | null
    readonly measure_type: MeasureType
    // readonly operator: Operator | null
}
const Aquifer = NamedId
export type Aquifer = S.Infer<typeof Aquifer>
const CoordinateSystem = S.assign(NamedId, S.object({
        proj4: S.string(),
        unit: S.enums(['metre', 'degree', 'unity']),
    }
))
export type CoordinateSystem = S.Infer<typeof CoordinateSystem>
const CoordinateDeterminationMethod = S.assign(NamedId, S.object({
    constant_name: S.nullable(S.enums(['from_map'])),
}))

export type CoordinateDeterminationMethod = S.Infer<typeof CoordinateDeterminationMethod>
const DataCorrelationStatus = S.assign(NamedId, S.object({
    constant_name: S.enums(['affected_up', 'affected_down', 'not_affected', 'no_result'])
}))
export type DataCorrelationStatus = S.Infer<typeof DataCorrelationStatus>
const DataStatus = S.assign(NamedId, S.object({
    constant_name: S.enums(['data_status_affected', 'data_status_invalid', 'data_status_not_checked', 'data_status_standard',]),
    sortorder: S.nullable(S.string()),
    standard: S.boolean(),
}))
export type DataStatus = S.Infer<typeof DataStatus>
const ErrorCode = S.assign(NamedId, S.object({
    constant_name: S.nullable(S.enums(['error_unavailable', 'error_no_reference', 'diver_uncompensated', 'diver_unreferenced',])),
    deprecated: S.boolean(),
    plot_value: S.enums(['BottomLevel', 'MinInPlot', 'Referencelevel', 'MeasuredValue']),
    sortorder: S.nullable(S.string()),
    standard: S.boolean(),
    symbol: S.nullable(S.string()),
}))
export type ErrorCode = S.Infer<typeof ErrorCode>
const FilterType = NamedId
export type  FilterType = S.Infer<typeof FilterType>
const HeightSystem2 = NamedId
export type  HeightSystem = S.Infer<typeof HeightSystem2>
const ObjectCategory = NamedId
export type  ObjectCategory = S.Infer<typeof ObjectCategory>
const ObjectStatus = S.assign(NamedId, S.object({
    constant_name: S.nullable(S.enums(['object_status_active', 'object_status_not_active'])),
}))
export type ObjectStatus = S.Infer<typeof ObjectStatus>
const ObjectType = S.assign(NamedId, S.object({
    object_category: ObjectCategory
}))
export type ObjectType = S.Infer<typeof ObjectType>
const Operator = S.assign(NamedId, S.object({standard: S.boolean()}))
export type  Operator = S.Infer<typeof Operator>
const PipeMaterial = NamedId
export type  PipeMaterial = S.Infer<typeof PipeMaterial>
const SettlementPosition = NamedId
export type SettlementPosition = S.Infer<typeof SettlementPosition>
const TipType = NamedId
export type TipType = S.Infer<typeof TipType>
export const DatabaseUtilities = S.object({
    aquifer: S.array(Aquifer),
    coordinate_system: S.array(CoordinateSystem),
    coordinate_determination_method: S.array(CoordinateDeterminationMethod),
    data_correlation_status: S.array(DataCorrelationStatus),
    data_status: S.array(DataStatus),
    deviation_status: S.array(DeviationStatus),
    error_code: S.array(ErrorCode),
    filter_type: S.array(FilterType),
    height_system: S.array(HeightSystem2),
    measure_type: S.array(MeasureType),
    measure_unit: S.array(MeasureUnit),
    object_category: S.array(ObjectCategory),
    object_status: S.array(ObjectStatus),
    object_type: S.array(ObjectType),
    operator: S.array(Operator),
    pipe_material: S.array(PipeMaterial),
    settlement_position: S.array(SettlementPosition),
    tip_type: S.array(TipType),
})
export type DatabaseUtilities = Readonly<S.Infer<typeof DatabaseUtilities>>
export const BackendVersion = S.object({
    backend_version: S.string(),
    database_version: S.integer(),
    database_name: S.string(),
    database_comment: S.string(),
    database_type: S.union([S.string(), S.enums(['production', 'development'])]),
})
export const GroupNoOids = S.assign(NamedId, S.object({
    global: S.boolean(),
}))
export const Group = S.assign(NamedId, S.object({
    global: S.boolean(),
    object_ids: S.array(S.integer()),
}))
export type Group = S.Infer<typeof Group>
export const User = S.assign(NamedId, S.object({
    email: S.string(),
    ability: S.nullable(S.enums(PROJECT_ABILITIES))
}))
export type User = S.Infer<typeof User>
export const UnosonCredential = S.object({
    client_id: S.string(),
    client_secret: S.string(),
    name: S.string(),
    password: S.string(),
    username: S.string(),
    // token: S.nullable(S.string()),
    // token_expires: S.nullable(S.integer()),
})
export type UnosonCredential = S.Infer<typeof UnosonCredential>
export const License = S.object({
    comment: S.nullable(S.string()),
    id: S.integer(),
    limit: S.record(S.enums(PROJECT_ABILITIES), S.integer()),
    name: S.string(),
    owner: S.nullable(S.string()),
    unoson_sources: S.array(UnosonCredential),
    used: S.record(S.enums(PROJECT_ABILITIES), S.integer()),
    users: S.array(S.object({
        name: S.string(),
        email: S.string(),
        ability: S.nullable(S.enums(PROJECT_ABILITIES)),
    })),
})
export type License = S.Infer<typeof License>
export const OwnedUser = S.assign(User, S.object({
    comment: S.nullable(S.string()),
    license_id: S.integer(),
    projects: S.array(S.assign(NamedId, S.object({
        ability: S.nullable(S.enums(PROJECT_ABILITIES)),
    }))),
    // license: License,
}))
export type OwnedUser = S.Infer<typeof OwnedUser>
export const KnownUser = S.assign(User, S.object({}))
export type KnownUser = S.Infer<typeof KnownUser>
export const SelfUser = S.assign(User, S.object({
    vide_admin: S.boolean(),
    owner_name: S.string(),
}))
export type SelfUser = S.Infer<typeof SelfUser>
/** Users in a project */
export const ProjectUser = S.assign(NamedId, S.object({
    email: S.string(),
    ability: S.enums(PROJECT_ABILITIES),
    license_name: S.string(),
    comment: S.nullable(S.string()),
}))
export type ProjectUser = S.Infer<typeof ProjectUser>
export const PROJECT_SHARE_TYPES = ['Private', 'License', 'Public'] as const
export const Project = S.assign(NamedId, S.object({
    comment: S.nullable(S.string()),
    correlations_exists: S.boolean(),
    coordinate_system_id: S.integer(),
    height_system_id: S.integer(),
    share_type: S.enums(PROJECT_SHARE_TYPES),
    license_id: S.integer(),
    owner_name: S.string(),
}))
export type Project = S.Infer<typeof Project>
export type ProjectWithLimit = Project & { dateLimit: string | null }
export type ProjectFull = Omit<Project,
    | 'height_system_id'
    | 'coordinate_system_id'
> & {
    coordinate_system: CoordinateSystem,
    height_system: HeightSystem,
}
/** Projects available to the current user */
export const UserProject = S.assign(Project, S.object({
    ability: S.enums(PROJECT_ABILITIES),
    correlation_options: S.nullable(S.object({confidence_level: S.number(), merging_period: S.integer()})),
    user_comment: S.nullable(S.string()),
    // extras: S.array(S.string()),
    unoson_sources: S.array(S.string()),
}))
export type UserProject = S.Infer<typeof UserProject>
const ImportType = S.object({
    description: S.nullable(S.string()),
    fileType: S.string(),
    ignoredHeaders: S.array(S.string()),
    info: S.array(S.string()),
    optionalHeaders: S.array(S.string()),
    requireCoordinateSystem: S.boolean(),
    // requireHeightSystem: S.boolean(),
    requiredHeaders: S.array(S.string()),
    template: S.nullable(S.string()),
    title: S.string(),
    type: S.string(),
    useAllowOldData: S.boolean(),
    useAllowUpdate: S.boolean(),
    useOriginalData: S.boolean(),
})
export type ImportType = S.Infer<typeof ImportType>
export const ImportParameter = S.object({
    importErrorAction: S.string(),
    maxFileSize: S.number(),
    maxRequestTime: S.string(),
    nameAllowUpdates: S.string(),
    nameOldData: S.string(),
    nameOriginalData: S.string(),
    periodOldData: S.number(),
    requestTimeLimit: S.string(),
    types: S.array(ImportType),
    unitOldData: S.string(),
    tooltips: S.object({
        allowUpdate: S.string(),
        originalInformation: S.string(),
        oldData: S.string(),
    })
})
export type ImportParameter = S.Infer<typeof ImportParameter>
export const GoteborgStation = S.object({
    age: S.literal(null),
    catchment: S.enums([
        "Centrala Göteborg",
        "Göteborg Nordöst",
        "Göteborg Sydväst",
        "Göteborg Öst",
        "Hisingen Norr",
        "Hisingen Syd",
        "Referensror",
    ]),
    groundLevel: S.nullable(S.number()),
    highres: S.boolean(),
    id: S.integer(),
    isActive: S.boolean(), //,
    lastMeasureValue: S.literal(null),
    // [
    // "SBK program"
    // ],
    measureTools: S.array(S.string()),
    // measureTools: S.array(S.enums(["SBK program", "Chalmerstunneln", "Hamnbanan", "45:an", "SGU refrör"])),
    name: S.string(),
    probability: S.literal(null),
    referenceLevel: S.nullable(S.number()),
    reservoir: S.enums(["Berg", "Undre", "Övre", "Övre ", "Portryck", "Unknown", "Osäkert"]),
    tipLevel: S.nullable(S.number()),
    x: S.number(),
    y: S.number()
})
export type GoteborgStation = S.Infer<typeof GoteborgStation>
export const UnosonLocation = S.object({
    /**
     "code": "41615301",
     "description": "Y1",
     "name": "Y1",
     "departmentcode": "P",
     "department": "Monitoring wells",
     "type": "ESENSE_PEILBUIS",
     "active": "1",
     "x": "59.233654",
     "y": "15.117853",
     "zonecode": "GW",
     "zone": "groundwater",
     "city": null,
     "address": null,
     "displaycode": "41615301",
     "lastmodified": "2022-04-05T13:24:33+0000",
     "lastmodified@uts": "1649165073",
     "id": "34313631-3533-3031",
     "xRd": 711113.5768299231,
     "yRd": 1291203.7671590429
     */
    // location: S.object({
        code: S.string(), //"41615301"
        description: S.string(),
        name: S.string(),
        departmentcode: S.enums(["PB", "P"]),
        department: S.enums(["Monitoring wells",]),
        type: S.string(),
        active: S.enums(['0', '1']),
        x: S.string(),
        y: S.string(),
        zonecode: S.enums(["PB", "GW",]),
        zone: S.enums(["Monitoring wells", "groundwater",]),
        city: S.nullable(S.string()),
        address: S.nullable(S.string()),
        displaycode: S.string(),//"38942637",
        lastmodified: S.string(),//"2024-02-22T12:14:59+0000",
        "lastmodified@uts": S.string(),//"1708604099",
        id: S.string(),
        xRd: S.number(),
        yRd: S.number(),
    // })
})
export type UnosonLocation = S.Infer<typeof UnosonLocation>
export const ObjectFile = S.object({
    id: S.number(),
    kind: S.enums(['picture', 'document']),
    name: S.string(),
    object_id: S.integer(),
})
export type ObjectFile = S.Infer<typeof ObjectFile>
export const ExternalObject = S.object({
    id: S.integer(),
    interval: S.number(),
    object_id: S.integer(),
    options: S.any(),
    source: S.string(),
})
export type ExternalObject = S.Infer<typeof ExternalObject>
export const UserOptions = S.partial(S.object({
    filename: S.string(),
    height: S.integer(),
    lastVersion: S.string(),
    width: S.integer(),
    dateZoom: S.object({interval: S.integer(), period: S.enums(Interval)})
}))
export type UserOptions = S.Infer<typeof UserOptions>

export const TriggerTypeValues = ['min', 'max'] as const
export const RawTrigger = S.object({
    active: S.boolean(),
    comment: S.nullable(S.string()),
    description: S.string(),
    id: S.integer(),
    measure_type_id: S.integer(),
    recipients: S.array(KnownUser),
    unchecked: S.boolean(),
    project_id: S.integer(),
    object_id: S.integer(),
    type: S.enums(TriggerTypeValues),
    limit: S.number(),
})
export type Trigger = Omit<S.Infer<typeof RawTrigger>,
    'measure_type_id'
> & {
    measureType: MeasureType
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Measurement related types
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export interface MeasurementWithValue extends Measurement {
    readonly resulting_value: number
}

export interface MeasurementResponse {
    readonly measure_type: MeasureType
    readonly measurements: Measurement[]
}

export interface ExtendedMeasurementResponse extends MeasurementResponse {
    // object: BasicObject
    readonly object: VideObject
    readonly project: ProjectWithLimit
}
