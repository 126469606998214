import {Injectable} from '@angular/core'

import {combineLatest, firstValueFrom, map, startWith, switchMap} from "rxjs"

import {PlotlyScattermapboxData, VideFigure} from "../../vide-types"
import {AbstractMapboxService} from "../../mapbox/abstract-mapbox.service"
import {CoordinateTransformService} from "../../shared/coordinate-transform.service"
import {PLOT_COLOR} from "../../../constants"
import {ExternalObject, GoteborgStation, Position} from "../../api/api-types"
import * as S from "superstruct"
import {LogContainer} from "../../log-list/log-container"
import {FormBuilder} from "@angular/forms"
import {SelectionModel} from "@angular/cdk/collections"

const ExternalObjectGoteborg = S.assign(ExternalObject, S.object({
    options: S.object({
        name: S.string(),
        // the rest are not used, but delivered... maybe we should stop them in the backend resource?
        updated: S.unknown(),
        // factor: S.unknown(),
    })
}))

export interface GoteborgObject {
    external: ExternalObject | null
    position: Position
    positionM: { x: number, y: number }
    station: GoteborgStation
    tainted: boolean
}

@Injectable({
    providedIn: 'root'
})
export class GoteborgService extends AbstractMapboxService {
    protected readonly useColorbar: boolean = false

    readonly inProgress = new SelectionModel<number>(true, [], true)
    readonly logs = new LogContainer('Save')
    readonly form = this.formBuilder.nonNullable.group({
        showInactive: [false],
    })

    readonly externalSelection$ = this.dataService.projectNotNull$.pipe(
        switchMap((p) => this.dataService.getExternalObjects(p, 'goteborg')),
        map(externals => S.create(externals, S.array(ExternalObjectGoteborg))),
    )
    readonly stations$ = combineLatest([
        this.dataService.getImportGoteborgStations(),
        this.dataService.utility$,
        this.externalSelection$,
        this.form.controls.showInactive.valueChanges.pipe(startWith(false)),
        this.inProgress.changed.pipe(startWith(false)),
    ]).pipe(
        map(([stations, util, ex, showInactive,]) => {
            const cs = util.coordinate_system.find(cs => cs.id === 3007)
            if (!cs) {
                throw Error("No coordinate system found")
            }
            const tr = this.transform.getTransformerToWgs(cs)
            const active =
                showInactive ? stations : stations.filter(s => s.isActive)
            return active.map(s => {
                const external = ex.find(x => x.options.name === s.name) ?? null
                const m = {x: s.x, y: s.y}
                const geo = tr(m)
                const ret: GoteborgObject = {
                    position: geo,
                    positionM: m,
                    station: s,
                    external,
                    tainted: this.inProgress.isSelected(s.id)
                }
                return ret
            })
        }),
    )

    readonly figure$ =
        this.stations$.pipe(
            map(stations => {
                const objectTrace: PlotlyScattermapboxData = {
                    customdata: stations as any[],
                    hovertemplate: stations.map(o => {
                        const p = o.station
                        const desc = []
                        {
                            let key: keyof typeof p
                            for (key in p) {
                                desc.push(`${key}: ${p[key]}`)
                            }
                        }
                        return `<em>%{text} </em> <br>` + desc.join("<br>") + "<extra></extra>"
                    }),
                    lat: stations.map(p => p.position.lat),
                    lon: stations.map(p => p.position.lon),
                    marker: {
                        color: stations.map(o =>
                            o.external ? PLOT_COLOR.object_selected : PLOT_COLOR.object_unselectd
                        ),
                    },
                    text: stations.map(s => s.station.name),
                    type: 'scattermapbox',
                }
                const layout = this.getLayout(stations, {background: 'open-street-map'})
                const config = this.getMapConfig({filename: 'asdf'})
                const figure: VideFigure = {
                    config,
                    data: [objectTrace],
                    layout,
                }
                return figure
            })
        )

    constructor(
        private formBuilder: FormBuilder,
        private readonly transform: CoordinateTransformService,
    ) {
        super()
    }

    async add(x: GoteborgObject) {
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        const properties = {name: getStationNameGoteborg(x)}
        this.inProgress.select(getStationIdGoteborg(x))
        const res = await firstValueFrom(
            this.dataService.createExternalObject(project, 'goteborg', properties)
        ).finally(() => {
            this.inProgress.deselect(getStationIdGoteborg(x))
        })
        this.logs.add(res, `Add ${x.station.name}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

    async remove(x: GoteborgObject) {
        if (!x.external) return
        const project = await firstValueFrom(this.dataService.projectNotNull$)
        this.inProgress.select(getStationIdGoteborg(x))
        const res = await firstValueFrom(
            this.dataService.deleteExternalSource(project, x.external)
        ).finally(() => {
            this.inProgress.deselect(getStationIdGoteborg(x))
        })
        this.logs.add(res, `Remove ${x.station.name}`)
        if (res.success) {
            this.dataService.reloadProjectData()
        }
    }

}

function getStationIdGoteborg(x: GoteborgObject) {
    return x.station.id
}

function getStationNameGoteborg(x: GoteborgObject) {
    return x.station.name
}
