import {AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms"
import {Component} from '@angular/core'
import {MatButtonModule} from "@angular/material/button"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatStepperModule} from "@angular/material/stepper"
import {MatTabsModule} from "@angular/material/tabs"
import {outputFromObservable, toSignal} from "@angular/core/rxjs-interop"

import {NgSelectModule} from "@ng-select/ng-select"
import {map, startWith} from "rxjs"

import {DiverService} from "../diver.service"
import {SlimObject,} from "../diver.component"
import {VideDataService} from "../../../api/vide-data.service"
import {getErrorMessage} from "../../../shared/vide-helper"

@Component({
    selector: 'app-select-diver-object',
    standalone: true,
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatStepperModule,
        MatTabsModule,
        NgSelectModule,
        ReactiveFormsModule,
    ],
    templateUrl: './select-diver-object.component.html',
    styleUrl: './select-diver-object.component.scss',
})
export class SelectDiverObjectComponent {
    protected readonly objects = toSignal(this.dataService.objects$, {initialValue: []})
    private readonly objectNameValidator: ValidatorFn = (control: AbstractControl) => {
        const value = control.value
        if (typeof value !== 'string') {
            return null
        }
        const name = value.trim()
        return this.objects().find(o => o.name === name) ? {nameExists: true} : null
    }

    // protected readonly form = this.diverService.form.controls.diverObject
    protected readonly form = this.fb.nonNullable.group({
        object: [null as null | SlimObject, Validators.required],
        name: ['', [Validators.required, this.objectNameValidator]],
    })
    protected readonly selectedObject$ = this.form.controls.object.valueChanges.pipe(
        startWith(null),
        map(() => this.form.controls.object.value),
    )
    protected readonly object = outputFromObservable(this.selectedObject$)

    constructor(
        private readonly dataService: VideDataService,
        private readonly diverService: DiverService,
        private readonly fb: FormBuilder,
    ) {
    }


    protected async newObject() {
        const control = this.form.controls.name
        control.markAllAsTouched()
        if (control.invalid) return
        const res = await this.diverService.createNewObject(control.value)
        if (res.success) {
            this.form.patchValue({object: res.data})
        }
    }

    protected readonly getErrorMessage = getErrorMessage

    localNext() {
        this.form.markAllAsTouched()
    }
}
