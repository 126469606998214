import {dateToString, isNotNull} from "src/app/shared/vide-helper"
import {PlotlyData} from "src/app/vide-types"

import {CORRELATION_OPTIONS, PLOT_CONFIG} from "src/constants"
import {measurementNumeric, measurementNormal} from "../measurement-categories"
import {getSequences, TimelineMarkers} from "../plot-functions"
import {Correlation, ExtendedMeasurementResponse, MeasurementResponse} from "../../api/api-types"
import {DASHES, DEFAULT_TIMELINE_MARKERS, getSplitter, getTimelineTracesV2} from "../timeline-plot/timeline-functions"

////////////////////////////////////////////////////////////////////////////////
// Correlation stuff
////////////////////////////////////////////////////////////////////////////////

/** Get trace for the reference object */
function getRefData(data: MeasurementResponse, corr: Correlation, start: Date): PlotlyData[] {
    const conf = PLOT_CONFIG.correlation.reference
    const dateString = dateToString(start)
    const measurements = data.measurements.filter(measurementNormal).filter(m => dateString <= m.measuretime)
    const sequences = getSequences(measurements, measurementNumeric)
    const type = "scatter" // scattergl does not do fill tonexty!
    const xxx = sequences.pass.map((seq, idx) => {
        const x = seq.map(m => m.measuretime)
        const singleValue = x.length === 1
        const y_middle = seq.map(m => f(m.resulting_value!))
        const y_lower = y_middle.map(m => m - corr.pred_ic)
        const y_upper = y_middle.map(m => m + corr.pred_ic)
        const text = seq.map(m => m.comment ?? '')

        const upper: PlotlyData = singleValue ? {
            // nothing here for single value segment
        } : {
            // normal trace, i.e. an invisible line
            x,
            y: y_upper,
            type,
            mode: 'lines',
            legendgroup: 'refdata',
            line: {width: 0},
            showlegend: false,
            hoverinfo: 'skip',
        }
        const lower: PlotlyData = singleValue ? {
            // vertical line for single value
            x: x.concat(x),
            y: y_lower.concat(y_upper),
            type,
            mode: 'lines',
            legendgroup: 'refdata',
            line: {width: conf.verticalLineWidth, color: conf.color},
            name: corr.ref_object.name + ' reference interval',
            // name: data.object.name + ' reference interval',
            showlegend: idx === 0,
            hoverinfo: 'all',
        } : {
            // normal trace, fill to the previous line (upper)
            x,
            y: y_lower,
            type,
            name: corr.ref_object.name + ' reference interval',
            // name: data.object.name + ' reference interval',
            fill: 'tonexty',
            fillcolor: conf.color,
            mode: 'none',
            legendgroup: 'refdata',
            showlegend: idx === 0,
            hoverinfo: 'skip',
        }
        const middle: PlotlyData = {
            x,
            y: y_middle,
            text,
            type,
            mode: singleValue ? 'markers' : 'lines',
            marker: {size: conf.middleMarkerSize, color: conf.middleColor},
            legendgroup: 'refdata',
            showlegend: false,
            line: {width: conf.middleLineSize, color: conf.middleColor},
            // hoverinfo: 'x+y',
            hoverinfo: 'text',
        }
        return [upper, lower, middle]
    }).flat()

    const limitLines = getLimitLines(corr)
    return [...xxx, ...limitLines]

    function f(x: number) {
        return x * corr.x_coeff + corr.intercept
    }

    function getLimitLines(corr: Correlation): PlotlyData[] {
        const xLimits = [corr.first_init_at, corr.last_init_at]
        const lowerRefValue = f(corr.ref_lim_min) - corr.pred_ic
        const upperRefValue = f(corr.ref_lim_max) + corr.pred_ic

        const lowerRef: PlotlyData = {
            x: xLimits, y: [1, 1].map(() => lowerRefValue),
            name: 'limit for reference values',
            type,
            legendgroup: 'refline',
            line: {color: conf.limitColor},
            hoverinfo: 'x+y',
        }
        const upperRef: PlotlyData = {
            x: xLimits, y: [1, 1].map(() => upperRefValue),
            showlegend: false,
            name: 'limit for reference values',
            type,
            legendgroup: 'refline',
            line: {color: conf.limitColor},
            hoverinfo: 'x+y',
        }
        return [lowerRef, upperRef]
    }
}

function getBaseData(data: ExtendedMeasurementResponse, markers: TimelineMarkers): PlotlyData[] {
    const split = getSplitter({
        legendMaxLength: 123123,
        yaxis: 'y',
        yaxisOptions: {transformKind: 'Resulting value', zeroLevelDateTime: ''},
        wrapYears: false,
        useMeasureTypeInLabel: false
    })(data)

    const yMin = split.valid.flat().map(x => x.value).filter(isNotNull).reduce((acc, curr) => acc < curr ? acc : curr, Infinity)
    const y = getTimelineTracesV2(
        split,
        {
            y: {markers: DEFAULT_TIMELINE_MARKERS},
            y2: {markers: DEFAULT_TIMELINE_MARKERS},
            wrapYears: false,
            legendBelow: false,
        }, {y: yMin, y2: Infinity}, PLOT_CONFIG.correlation.base.color, DASHES[0])
    return y
}

export function getCorrelationData(
    base: ExtendedMeasurementResponse,
    ref: MeasurementResponse,
    corr: Correlation,
    markers: TimelineMarkers,
) {
    const startRef = base.measurements.length > 0
        ? new Date(base.measurements[0]!.measuretime)
        : new Date()
    startRef.setMonth(startRef.getMonth() - CORRELATION_OPTIONS.refStartAheadMonths)

    const baseData = getBaseData(base, markers)
    const refData = getRefData(ref, corr, startRef)

    return [
        ...refData,
        ...baseData,
    ]
}
