@if (figure$|async; as plot) {
    <plotly-plot
        [data]="plot.data"
        [layout]="plot.layout"
        [config]="plot.config"
        [useResizeHandler]="true"
        [style]="plotlyStyle()"
        (relayout)="relayout($event)"
        (afterPlot)="afterPlot()"
        (hover)="hover($event)"
        (unhover)="unhover($event)"
    />
}

<mat-accordion multi>

    <mat-expansion-panel class="vide-form-field" [expanded]="ACCORDION_STATE.y">
        <mat-expansion-panel-header>
            Primary Y-axis
        </mat-expansion-panel-header>
        <app-timeline-trace-options
            [formGroup]="form.controls.y"
            [horizontals]="horizontal()"
            [measureTypes]="measureTypes()"
            [useCorrelation]="usesCorrelation()"
        />
        <mat-action-row>
            <button mat-raised-button (click)="form.controls.y.reset()" matTooltip="Reset options">
                <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
            </button>
        </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel class="vide-form-field" [expanded]="ACCORDION_STATE.y2">
        <mat-expansion-panel-header>
            Secondary Y-axis
        </mat-expansion-panel-header>

        <app-timeline-trace-options
            [formGroup]="form.controls.y2"
            [horizontals]="horizontal2()"
            [measureTypes]="measureTypes()"
            [useCorrelation]="usesCorrelation()"
        />
        <mat-action-row>
            <button mat-raised-button (click)="form.controls.y2.reset()" matTooltip="Reset options">
                <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
            </button>
        </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel class="vide-form-field" [expanded]="ACCORDION_STATE.axes">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Axes and colors
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="vide-columns vide-form-field-row">
            <mat-slide-toggle [formControl]="form.controls.wrapYears">Compare years</mat-slide-toggle>
            <mat-slide-toggle [formControl]="form.controls.legendBelow">Legend below</mat-slide-toggle>
            <!-- Colors -->
            <div class="vide-form-field-row">
                <ng-select [formControl]="form.controls.colorSequence"
                           [items]="TIMELINE_COLOR_SEQUENCES | keyvalue" bindValue="key"
                           [clearable]="false">
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.key }} ({{ item.value.length }})
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        Color sequence: {{ item.key }}
                    </ng-template>
                </ng-select>
            </div>
        </div>
        @if (form.controls.axes.controls; as cntrls) {
            <table>
                <tbody>
                <tr>
                    <td> Time range:</td>
                    <td>
                        <app-input placeholder="" type="date" [max]="INPUT_DATE_MAX" [formControl]="cntrls.xmin"/>
                    </td>
                    <td>&mdash;</td>
                    <td>
                        <app-input placeholder="" type="date" [max]="INPUT_DATE_MAX" [formControl]="cntrls.xmax"/>
                    </td>
                    <td>
                        <button mat-raised-button (click)="clearDateRange()"
                                matTooltip="Apply automatic axis to display full range"> Reset
                        </button>
                    </td>
                </tr>
                <tr>
                    <td> Y-axis range:</td>
                    <td>
                        <app-input placeholder="min" type="number" [step]="0.5" [formControl]="cntrls.ymin"/>
                    </td>
                    <td>&mdash;</td>
                    <td>
                        <app-input placeholder="max" type="number" [step]="0.5" [formControl]="cntrls.ymax"/>
                    </td>
                    <td>
                        <button mat-raised-button (click)="clearYRange()"
                                matTooltip="Apply automatic axis to display full range"> Reset
                        </button>
                    </td>
                </tr>
                <tr>
                    <td> Y2-axis range:</td>
                    <td>
                        <app-input placeholder="min" type="number" [step]="0.5" [formControl]="cntrls.y2min"/>
                    </td>
                    <td>&mdash;</td>
                    <td>
                        <app-input placeholder="max" type="number" [step]="0.5" [formControl]="cntrls.y2max"/>
                    </td>
                    <td>
                        <button mat-raised-button (click)="clearY2Range()"
                                matTooltip="Apply automatic axis to display full range"> Reset
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        }
        <mat-action-row>
            <button mat-raised-button (click)="form.controls.axes.reset()" matTooltip="Reset options">
                <mat-icon aria-label="Reset options" fontIcon="restart_alt"></mat-icon>
            </button>
        </mat-action-row>


    </mat-expansion-panel>

</mat-accordion>
