<table mat-table [dataSource]="installations$">

    <ng-container matColumnDef="first_date">
        <th mat-header-cell *matHeaderCellDef> First value (local time)</th>
        <td mat-cell *matCellDef="let element"> {{ element.first_date }}</td>
    </ng-container>
    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="delete(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef> Serial number of diver</th>
        <td mat-cell *matCellDef="let element"> {{ element.serial_number }}</td>
    </ng-container>
    <mat-text-column name="comment"/>
    <mat-text-column name="id"/>
    <mat-text-column name="object_id"/>
    <mat-text-column name="reference_measurement_id"/>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="stepper-navigate">
    <button mat-button matStepperPrevious type="button">Previous</button>
    <button mat-button matStepperNext type="button" disabled>Next</button>
</div>
